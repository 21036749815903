@use '~/styles' as *;

.input {
  min-width: 250px;
  max-width: none;
}

.actions {
  text-align: center;

  svg {
    fill: var(--color-fg-muted);
  }
}
