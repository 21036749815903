@use '~/styles' as *;

.root {
  padding: var(--spacing-16);
  background-color: var(--color-neutral-30);
}

.chart {
  height: var(--cow-chart-height);
}
