@use '~/styles' as *;

.light {
  --button-active-color: var(--color-accent-hover);
  --button-pressed-color: var(--color-accent-active);
  --button-disabled-color: var(--color-fg-disabled);
}

.dark {
  --button-active-color: var(--color-accent-100);
  --button-pressed-color: var(--color-accent-50);
  --button-disabled-color: var(--color-neutral-150);
}

.primary {
  &.light {
    --button-default-color: var(--color-accent-default);
  }

  &.dark {
    --button-default-color: var(--color-accent-200);
  }
}

.secondary {
  &.light {
    --button-default-color: var(--color-fg-default);
  }

  &.dark {
    --button-default-color: var(--color-neutral-50);
  }
}

.tertiary {
  &.light {
    --button-default-color: var(--color-fg-soft);
  }

  &.dark {
    --button-default-color: var(--color-neutral-200);
  }
}

.success {
  &.light {
    --button-default-color: var(--color-success-default);
    --button-active-color: var(--color-success-hover);
    --button-pressed-color: var(--color-success-active);
  }

  &.dark {
    --button-default-color: var(--color-success-200);
    --button-active-color: var(--color-success-100);
    --button-pressed-color: var(--color-success-50);
  }
}

.failure {
  &.light {
    --button-default-color: var(--color-error-default);
    --button-active-color: var(--color-error-hover);
    --button-pressed-color: var(--color-error-active);
  }

  &.dark {
    --button-default-color: var(--color-error-200);
    --button-active-color: var(--color-error-100);
    --button-pressed-color: var(--color-error-50);
  }
}

.root {
  --button-color: var(--button-default-color);

  position: relative;
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  padding: 0;
  border: none;
  border-radius: var(--border-radius-6);
  background: transparent;
  color: var(--button-color);
  cursor: pointer;
  gap: var(--spacing-4);
  outline: none;
  user-select: none;
  white-space: nowrap;

  &:hover {
    --button-color: var(--button-active-color);
  }

  &.focused {
    --button-color: var(--button-active-color);

    outline: var(--border-width-focus) solid var(--color-focus-default);
  }

  &.pressed {
    --button-color: var(--button-pressed-color);
  }

  &.disabled {
    --button-color: var(--button-disabled-color);

    cursor: not-allowed !important;
  }
}
