@use '~/styles' as *;

.root {
  --tab-border: 1px solid var(--color-neutral-75);
  --tab-highlight-background: transparent;
  --tab-highlight-height: 2px;

  display: flex;
}

.primary {
  --tab-default-background: var(--color-neutral-30);
  --tab-active-background: var(--color-static-white);

  .tab {
    outline: none;

    &.focused {
      outline: var(--border-width-focus) solid var(--color-focus-default);
      outline-offset: var(--spacing-2);
    }
  }

  .tab:not(.active):has(+ .tab:not(.active)) {
    border-right: var(--tab-border);
  }

  .active {
    border-radius: var(--border-radius-8) var(--border-radius-8) 0 0;
  }
}

.secondary {
  --tab-default-background: var(--color-static-white);
  --tab-active-background: var(--color-neutral-30);

  .tab {
    border: var(--tab-border);
    border-left: none;
    outline: none;

    &:first-child {
      border-left: var(--tab-border);
      border-top-left-radius: var(--border-radius-4);
    }

    &:last-child {
      border-top-right-radius: var(--border-radius-4);
    }

    &.active {
      border-bottom: none;
    }

    &.focused {
      outline: var(--border-width-focus) solid var(--color-focus-default);
      outline-offset: var(--spacing-2);
    }
  }
}

.tab {
  padding: var(--spacing-16);
  background: var(--tab-default-background);
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
}

.active {
  background: var(--tab-active-background);
}

// TODO remove other styles and move this to .root, when we rework cow page with old tabs
.page {
  --tab-default-background: transparent;
  --tab-active-background: transparent;

  gap: var(--spacing-16);

  .tab {
    position: relative;
    padding: var(--spacing-6) var(--spacing-4);
    border-radius: var(--border-radius-8);
    outline: none;

    &:not(:is(:hover, .pressed, .focused, .active)) {
      color: var(--color-fg-soft);
    }

    &:hover {
      --tab-highlight-background: var(--color-neutral-container-hover);
    }

    &.pressed {
      --tab-highlight-background: var(--color-neutral-container-active);
    }

    &.focused {
      --tab-highlight-background: var(--color-neutral-container-hover);

      outline: var(--border-width-focus) solid var(--color-focus-default);
      outline-offset: var(--spacing-2);
    }

    &.active {
      --tab-highlight-background: var(--color-accent-default);

      &:hover {
        --tab-highlight-background: var(--color-accent-hover);
      }

      &.pressed {
        --tab-highlight-background: var(--color-accent-active);
      }

      &.focused {
        --tab-highlight-background: var(--color-accent-hover);
      }
    }

    &::after {
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      height: var(--tab-highlight-height);
      background: var(--tab-highlight-background);
      content: '';
    }
  }
}
