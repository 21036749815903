@use '~/styles' as *;

// TODO remove this, after EditSemenDoseModal refactor to new inputs
.m-input {
  &.m-error {
    label {
      color: var(--color-error-default);
    }
  }

  label {
    display: block;
    margin-bottom: var(--spacing-4);
    color: var(--color-fg-soft);
  }

  input {
    &.m-control {
      @include field-border;
      @include body-small;

      padding: var(--spacing-8);
    }

    &::placeholder {
      color: var(--color-fg-muted);
      letter-spacing: 0.0275em;
    }

    &::-webkit-datetime-edit {
      color: var(--color-fg-default);
    }

    &::-webkit-datetime-edit-text {
      color: var(--color-fg-default);
    }

    min-width: 45px;
    border: none;
    background: none;
    outline: none;

    &.full {
      width: 100%;
    }

    &.light {
      //   color: $darkTextColor;
      //   border-bottom-color: $darkTextColor;

      &::placeholder {
        // color: $darkTextColor;
      }
    }
  }
}
