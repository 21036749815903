@use '~/styles' as *;

.root {
  &:not(.disabled) {
    cursor: pointer;

    &.selected {
      background: var(--color-accent-container-default);
    }

    &.highlighted,
    &:hover {
      background: var(--color-accent-opaque-container-hover);
    }
  }

  &:focus {
    outline: none;
  }
}

.item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--spacing-8) var(--menu-item-horizontal-spacing);
  outline: none;
}
