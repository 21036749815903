@use '~/styles' as *;

.groupRow {
  font-weight: 600;

  &.firstLevel {
    position: sticky;
    top: 40px;
    background-color: var(--color-neutral-50);

    td {
      padding-left: var(--spacing-16);
    }
  }

  &.shift {
    td:first-child {
      padding-left: var(--spacing-24);
    }
  }

  svg {
    fill: var(--color-fg-soft);
  }
}
