@use '~/styles' as *;

.root {
  --menu-item-horizontal-spacing: var(--spacing-12);

  display: flex;
  overflow: hidden;
  max-height: var(--popover-max-height);
  flex-direction: column;
}

.menu {
  overflow: auto;
  padding: var(--spacing-4) 0;
  overscroll-behavior: contain;

  @include custom-scrollbar(var(--color-bg-surface-1));
}

.item {
  position: relative;
}

.additionalListItem {
  composes: item;
  display: grid;
  padding: var(--spacing-4);
  border-top: 1px solid var(--color-border-muted);
}

.delimiter {
  width: 100%;
  height: 1px;
  margin: var(--spacing-4) 0;
  background: var(--color-border-muted);
}
