@use '~/styles' as *;

.root {
  padding: var(--spacing-12);
  border-radius: var(--border-radius-8);
  background: var(--color-bg-surface-3);
}

.closeButton {
  height: var(--size-24);
}
