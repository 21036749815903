@use '~/styles' as *;

.form {
  display: flex;
  flex-direction: row;
  gap: var(--spacing-8);
}

.group {
  min-width: 300px;
}
