/**
 * Do not edit directly
 * Generated on Mon, 03 Feb 2025 08:55:05 GMT
 */

@use './typography-mixins' as *;

.bodyLarge {
  @include body-large;
}

.bodyLargeStrong {
  @include body-large-strong;
}

.bodyMedium {
  @include body-medium;
}

.bodyMediumStrong {
  @include body-medium-strong;
}

.bodySmall {
  @include body-small;
}

.bodySmallStrong {
  @include body-small-strong;
}

.descriptionLarge {
  @include description-large;
}

.descriptionLargeStrong {
  @include description-large-strong;
}

.descriptionMedium {
  @include description-medium;
}

.descriptionMediumStrong {
  @include description-medium-strong;
}

.descriptionSmall {
  @include description-small;
}

.descriptionSmallStrong {
  @include description-small-strong;
}

.displayLarge {
  @include display-large;
}

.displayLargeStrong {
  @include display-large-strong;
}

.displayMedium {
  @include display-medium;
}

.displayMediumStrong {
  @include display-medium-strong;
}

.displaySmall {
  @include display-small;
}

.displaySmallStrong {
  @include display-small-strong;
}

.heading1 {
  @include heading-1;
}

.heading2 {
  @include heading-2;
}

.heading3 {
  @include heading-3;
}

.heading4 {
  @include heading-4;
}

.heading5 {
  @include heading-5;
}
