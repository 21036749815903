@use '~/styles' as *;

.m-dropdown {
  @include dropdown-base;

  .m-dropdown-content {
    @include dropdown-content;
  }

  .m-dropdown-list {
    @include dropdown-list;
  }

  .m-dropdown-list-item {
    @include dropdown-list-item;
  }
}
