@use '~/styles' as *;

.root {
  display: flex;
  align-items: flex-start;
  padding: var(--spacing-12) var(--spacing-16) var(--spacing-12)
    var(--spacing-8);
  border-radius: var(--border-radius-8);
  background-color: var(--notification-dark-container-color);
  color: var(--color-neutral-on-neutral);
}
