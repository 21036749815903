.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.hidden-scrollbar {
  -ms-overflow-style: none; // IE and Edge
  scrollbar-width: none; // Firefox

  // Chrome, Safari and Opera
  &::-webkit-scrollbar {
    display: none;
  }
}
