@use '~/styles' as *;

.titleWrapper {
  position: relative;
  display: inline-flex;
  align-items: center;
  padding: var(--spacing-2) 0;
  border-radius: var(--border-radius-8);
  cursor: pointer;
  outline: none;
  user-select: none;

  &.focused {
    outline: var(--border-width-focus) solid var(--color-focus-default);
    outline-offset: var(--spacing-2);
  }
}

.children {
  overflow: hidden;
  transition: max-height var(--duration-fast-01) ease-out;
}
