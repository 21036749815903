@use '~/styles' as *;

.m-spinner {
  width: var(--spacing-24);
  height: var(--spacing-24);
  border: var(--spacing-4) solid var(--color-neutral-30);
  border-radius: 50%;
  border-top: var(--spacing-4) solid var(--color-accent-default);
  animation: --keyframes-spin 2s linear infinite;
}

@keyframes --keyframes-spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
