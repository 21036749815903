@use '~/styles' as *;

.root {
  --section-border: 1px solid var(--color-border-muted);
}

.section {
  display: grid;
  padding: var(--spacing-16);
}

.borderedSection {
  composes: section;
  border-top: var(--section-border);
  border-bottom: var(--section-border);
}
