@use '../abstracts' as *;

// 2px

.mt-2 {
  margin-top: var(--spacing-2) !important;
}

.mb-2 {
  margin-bottom: var(--spacing-2) !important;
}

// 4px

.ml-4 {
  margin-left: var(--spacing-4) !important;
}

.mr-4 {
  margin-right: var(--spacing-4) !important;
}

.mt-4 {
  margin-top: var(--spacing-4) !important;
}

.mb-4 {
  margin-bottom: var(--spacing-4) !important;
}

// 8px

.m-8 {
  margin: var(--spacing-8);
}

.mt-8 {
  margin-top: var(--spacing-8);
}

.mr-8 {
  margin-right: var(--spacing-8) !important;
}

.mb-8 {
  margin-bottom: var(--spacing-8);
}

.ml-8 {
  margin-left: var(--spacing-8) !important;
}

.my-8 {
  margin-top: var(--spacing-8);
  margin-bottom: var(--spacing-8);
}

.mx-8 {
  margin-right: var(--spacing-8);
  margin-left: var(--spacing-8);
}

// 12px

.mt-12 {
  margin-top: var(--spacing-12);
}

.mb-12 {
  margin-bottom: var(--spacing-12);
}

.ml-12 {
  margin-left: var(--spacing-12);
}

.mr-12 {
  margin-right: var(--spacing-12);
}

// 16px

.m-16 {
  margin: var(--spacing-16) !important;
}

.mt-16 {
  margin-top: var(--spacing-16) !important;
}

.mb-16 {
  margin-bottom: var(--spacing-16) !important;
}

.ml-16 {
  margin-left: var(--spacing-16) !important;
}

.mr-16 {
  margin-right: var(--spacing-16) !important;
}

.my-16 {
  margin-top: var(--spacing-16) !important;
  margin-bottom: var(--spacing-16) !important;
}

.mx-16 {
  margin-right: var(--spacing-16) !important;
  margin-left: var(--spacing-16) !important;
}

// 24px

.mt-24 {
  margin-top: var(--spacing-24) !important;
}

.mr-24 {
  margin-right: var(--spacing-24) !important;
}

.mb-24 {
  margin-bottom: var(--spacing-24) !important;
}

.my-24 {
  margin-top: var(--spacing-24) !important;
  margin-bottom: var(--spacing-24) !important;
}

// 32px

.mt-32 {
  margin-top: var(--spacing-32) !important;
}

.my-32 {
  margin-top: var(--spacing-32) !important;
  margin-bottom: var(--spacing-32) !important;
}

.mb-32 {
  margin-bottom: var(--spacing-32) !important;
}

// 40px

.mt-40 {
  margin-top: var(--spacing-40) !important;
}

.mb-40 {
  margin-bottom: var(--spacing-40) !important;
}

// auto

.mt-a {
  margin-top: auto !important;
}

.ml-a {
  margin-left: auto !important;
}

.mr-a {
  margin-right: auto;
}

// negative 4px

.-mt-4 {
  margin-top: calc(-1 * var(--spacing-4));
}

// negative 8px

.-ml-8 {
  margin-left: calc(-1 * var(--spacing-8));
}
