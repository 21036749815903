@use '~/styles' as *;

.root {
  --no-children-message-height: 112px;
}

.titleWrapper {
  position: relative;
  display: inline-flex;
  align-items: flex-start;
  padding: var(--spacing-2) var(--spacing-4) var(--spacing-2) 0;
  border-radius: var(--border-radius-8);
  gap: var(--spacing-4);
  outline: none;
  user-select: none;

  &.focused {
    outline: var(--border-width-focus) solid var(--color-focus-default);
    outline-offset: var(--spacing-2);
  }
}

.noChildrenMessage {
  display: grid;
  height: var(--no-children-message-height);
  padding: var(--spacing-16);
  border: var(--border-width-1) solid var(--color-border-muted);
  border-radius: var(--border-radius-6);
  place-items: center;
  text-align: center;
}

.children {
  overflow: clip;

  // For focus outlines on checkboxes
  overflow-clip-margin: var(--border-width-2);
  padding-inline: var(--spacing-16);
  transition: max-height var(--duration-fast-01) ease-out;
}
