@use '~/styles' as *;

.root {
  &:is(
      :hover,
      :has([data-is-focus-visible='true']),
      :has([aria-selected='true'])
    )
    .favorite {
    opacity: 1;
  }
}

.favorite {
  opacity: 0;
}
