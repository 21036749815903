@use '~/styles' as *;

.m-backdrop {
  position: absolute;
  z-index: calc(var(--z-index-old-menu) + 1);
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  background-color: var(--color-neutral-container-active);
}
