@use '~/styles' as *;

.m-variable-editor-master-field-item {
  color: var(--color-accent-700);
}

.m-variable-editor-field-item {
  color: var(--color-accent-400);
}

.m-variable-editor-cycle-input-item {
  color: var(--color-success-default);
}
