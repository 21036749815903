@use '~/styles' as *;

.root {
  --text-block-max-width: 800px;
  --content-left-margin: var(--spacing-12);

  display: flex;
  align-items: flex-start;
  border-radius: var(--border-radius-8);
  background-color: var(--notification-light-container-color);
}

.icon {
  margin-left: var(--spacing-8);
  color: var(--notification-default-color);
}

.textWrapper {
  max-width: var(--text-block-max-width);
}

.small8 {
  --content-left-margin: var(--spacing-8);

  padding: var(--spacing-8) var(--spacing-12) var(--spacing-8) var(--spacing-4);

  .functionButtonItem {
    align-self: flex-start;
  }
}

.medium16 {
  --content-left-margin: var(--spacing-12);

  padding: var(--spacing-16) var(--spacing-16) var(--spacing-16)
    var(--spacing-8);
}

.contentWrapper {
  margin-left: var(--content-left-margin);
}

.functionButtonItem {
  flex-shrink: 0;
  align-self: flex-end;
}
