@use '~/styles' as *;

.notificationsContainer {
  position: fixed;
  z-index: var(--z-index-notifications);
  overflow: hidden;
  max-height: 100%;
  overscroll-behavior: contain;
  pointer-events: none;
}

.alertsContainer {
  composes: notificationsContainer;
  right: 0;
  bottom: 0;
  padding: var(--spacing-32);
}

.toastsContainer {
  composes: notificationsContainer;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: var(--spacing-32) 0;
}
