@use '~/styles' as *;

.m-inline-editor {
  display: inline-flex;
  flex: 1;
  align-items: center;
  margin: 0 var(--spacing-8);

  svg {
    margin: 0 var(--spacing-8);
    fill: none;
    stroke: var(--color-fg-default);
  }

  input {
    @include field-border;

    width: 100%;
    padding: var(--spacing-1) var(--spacing-4);

    &:focus {
      outline: none;
    }
  }
}
