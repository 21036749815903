@use '~/styles' as *;

.root {
  --move-cows-key-icon-width: 180px;
  --cow-icon-width: 72px;

  background-color: var(--color-bg-page);
}

.children {
  position: relative;
  overflow: hidden;
  padding: 0 var(--spacing-16);
  transition:
    max-height var(--duration-fast-01) ease-out,
    padding-bottom var(--duration-fast-01) ease-out,
    padding-top var(--duration-fast-01) ease-out;
}

.open {
  .children {
    padding: var(--spacing-8) var(--spacing-16) var(--spacing-16)
      var(--spacing-16);
  }
}

.cowIcon {
  width: var(--cow-icon-width);
}

.moveCowsKeyIcon {
  width: var(--move-cows-key-icon-width);
}

.titleWrapper {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin: var(--spacing-16);
  cursor: pointer;
  outline: none;
  user-select: none;

  &.focused {
    border-radius: var(--border-radius-4);
    outline: var(--border-width-focus) solid var(--color-focus-default);
    outline-offset: var(--spacing-4);
  }

  &:hover {
    .hoveredDeleteIcon {
      opacity: 1;
    }
  }
}

.hoveredDeleteIcon {
  opacity: 0;

  &:focus-visible {
    opacity: 1;
  }
}

.deleteKeyInfoWrapper {
  display: flex;
  flex-direction: column;
  padding: var(--spacing-16);
  border: var(--border-width-1) solid var(--color-border-muted);
  border-radius: var(--border-radius-8);
  gap: var(--spacing-16);
}
