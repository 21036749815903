@use '~/styles' as *;

.input {
  width: 70px;
  margin: 0 var(--spacing-8);
}

.textString {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: var(--spacing-8) 0;
}

.select {
  min-width: 120px;
}

.caption {
  margin-top: var(--spacing-24);
  font-weight: bold;
}
