@use '~/styles' as *;

.nameCell {
  position: sticky;
  z-index: var(--z-index-base);
  left: 0;
  border-right: var(--border-width-1) solid var(--color-border-muted);
  animation: --keyframes-scroll-colored-border step-end reverse;
  animation-timeline: scroll(inline);
}

.groupName {
  position: sticky;
  left: calc(var(--spacing-16) + 1px);
  display: inline;
}

.targetCell {
  position: sticky;
  z-index: var(--z-index-base);
  right: 0;
  border-left: var(--border-width-1) solid var(--color-border-muted);
  animation: --keyframes-scroll-colored-border step-end;
  animation-timeline: scroll(inline);
}

@keyframes --keyframes-scroll-colored-border {
  from {
    border-right-color: var(--color-border-soft);
    border-left-color: var(--color-border-soft);
  }

  to {
    border-right-color: var(--color-border-muted);
    border-left-color: var(--color-border-muted);
  }
}

.errorCell {
  position: relative;
  border-style: solid;
  border-color: var(--color-error-soft) !important;
  border-right-width: var(--border-width-1);
  border-left-width: var(--border-width-1);

  // top horizontal border (to resolve top-bottom double borders problem)
  &::before {
    position: absolute;
    top: -1px;

    // 101% to cover both sides border
    width: 101%;
    height: var(--border-width-1);
    background-color: var(--color-error-soft);
    content: '';
  }
}

.errorCell:has(+ .errorCell) {
  border-right-width: 0;
}
