.root {
  display: grid;
  place-items: center;
}

.spinner {
  animation: --keyframes-spinner 1.4s infinite linear;
}

@keyframes --keyframes-spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
