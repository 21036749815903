@use '~/styles' as *;

.tableHeader {
  display: grid;
  align-self: stretch;
  padding: 0 var(--spacing-16) var(--spacing-12);
  border-bottom: var(--border-width-1) solid var(--color-border-muted);
  margin-bottom: var(--spacing-4);
  gap: var(--spacing-12);
  grid-template-columns: var(--edit-company-table-columns);
}

.tableRow {
  --rows-count: 1;

  display: grid;
  align-self: stretch;
  padding: var(--spacing-12) var(--spacing-16);
  border-radius: var(--border-radius-8);
  background: var(--color-bg-surface-1);
  gap: var(--spacing-12);
  grid-template-columns: var(--edit-company-table-columns);
  grid-template-rows: repeat(var(--rows-count), auto);

  &:has(.input :nth-child(2)) {
    --rows-count: 2;
  }

  &:hover {
    .deleteButton {
      opacity: 1;
    }
  }
}

.input {
  grid-row: 1 / span var(--rows-count);
  grid-template-rows: subgrid;
}

.deleteButton {
  align-self: center;
  opacity: 0;

  &[data-is-focus-visible='true'] {
    opacity: 1;
  }
}
