@use '~/styles' as *;

.source {
  @include blueprint-edit-section(var(--color-accent-soft));
}

.actions {
  @include blueprint-edit-section(var(--color-status04-soft));
}

.view {
  @include blueprint-edit-section(transparent);
}

.sectionItems {
  height: 300px;
  flex-grow: 1;
  overflow-y: auto;

  @include custom-scrollbar(var(--color-bg-surface-1));
}

.controls {
  display: flex;
  flex-direction: row;
}
