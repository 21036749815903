@use '~/styles' as *;

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.action {
  @include node-header-action;
}
