@use '~/styles' as *;

.root {
  width: 100%;
  padding: var(--spacing-16) var(--spacing-24);
  background-color: var(--color-bg-surface-5);
}

.content {
  display: flex;
  width: 80%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
}
