@use '~/styles' as *;

.root {
  display: grid;
  padding: var(--spacing-6) var(--spacing-8);
  border-radius: var(--border-radius-8);
  background: var(--color-neutral-container-muted);
  color: var(--color-fg-soft);
  cursor: pointer;
  grid-template-columns: var(--size-24) 1fr auto;
  outline: none;

  &:hover {
    background: var(--color-neutral-container-soft);
  }

  &.focused {
    background: var(--color-neutral-container-soft);
    outline: var(--border-width-focus) solid var(--color-focus-default);
    outline-offset: var(--spacing-2);
  }

  &.pressed {
    background: var(--color-neutral-container-default);
  }
}

.hideForCollapsed {
  opacity: var(--collapsible-content-opacity);
}

.hideForCollapsedWithTransition {
  composes: hideForCollapsed;
  transition: opacity var(--duration-medium-02) ease-in-out;
}
