@use '~/styles' as *;

.cowChartLinesColumn {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-4);
}

.cowEvents {
  display: grid;
  margin-top: var(--spacing-4);
  gap: var(--spacing-4) var(--spacing-16);
  grid-template-columns: 1fr 1fr;
}

.circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.itemWithIcon {
  display: flex;
  align-items: center;
  gap: var(--spacing-4);
  white-space: nowrap;
}

.lineIcon {
  position: relative;
  top: 1px;
  width: 16px;
  height: 0;
}

.milk {
  composes: lineIcon;
  border-bottom: 2px solid var(--color-fg-muted);
}

.testMilk {
  composes: lineIcon;
  border-bottom: 2px dotted var(--color-fg-muted);
}

.predictedMilk {
  composes: lineIcon;
  display: flex;
  align-items: center;

  &::before,
  &::after {
    display: block;
    width: 50%;
    content: '';
  }

  &::before {
    border-bottom: 2px solid var(--color-fg-muted);
  }

  &::after {
    border-bottom: 2px dotted var(--color-fg-muted);
  }
}
