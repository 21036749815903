@use '~/styles' as *;

.root {
  --column-width: 52px;

  display: flex;
  padding: 4px 74px 60px;
  gap: var(--spacing-12);
}

.column {
  display: flex;
  width: var(--column-width);
  flex-direction: column;
  align-items: flex-end;
  gap: var(--spacing-16);

  & :nth-child(n + 6) {
    align-self: center;
  }

  & :nth-child(n + 11) {
    align-self: flex-start;
  }
}
