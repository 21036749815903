@use 'sass:color';
@use '~/styles' as *;

.root {
  display: flex;
  min-width: 250px;
  flex-flow: row wrap;
}

.day {
  display: flex;
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--color-border-soft);
  border-radius: 20px;
  margin: var(--spacing-4);
  background-color: var(--color-static-white);
  color: var(--color-fg-soft);
  cursor: pointer;

  &:first-child {
    margin-left: 0;
  }
}

.selected {
  border-color: var(--color-accent-muted);
  background-color: white;
  color: var(--color-accent-on-container);
}
