@use '~/styles' as *;

.root {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--spacing-12);
  border-radius: var(--border-radius-8);
  background: var(--color-bg-surface-3);
  cursor: pointer;

  &:hover {
    background: var(--color-neutral-opaque-container-default);
  }
}
