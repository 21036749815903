@use '~/styles' as *;

.editable {
  padding: 2px;
  border-radius: var(--border-radius-6);

  &:focus {
    background-color: var(--color-bg-disabled);
    outline: none;
  }
}
