@use '~/styles' as *;

.root {
  --avatar-size: var(--size-24);

  display: grid;
  width: var(--avatar-size);
  min-width: var(--avatar-size);
  height: var(--avatar-size);
  border-radius: var(--border-radius-6);
  color: var(--color-fg-soft);
  place-items: center;
  user-select: none;
}
