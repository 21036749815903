@use '~/styles' as *;

.hiddenDataCaption {
  --hidden-data-caption-min-width: 388px;
  --hidden-data-caption-min-height: 160px;

  --hidden-grid-line-start: -2;

  z-index: calc(var(--z-index-sticky-scrollbar) - 1);
  display: grid;
  padding: var(--spacing-16);
  backdrop-filter: blur(12px);
  background: var(--color-bg-surface-0);
  place-items: center;
}

.hiddenDataColumnsCaption {
  composes: hiddenDataCaption;
  min-width: var(--hidden-data-caption-min-width);
  border-left: var(--border-width) solid var(--border-color);
  grid-column: var(--hidden-grid-line-start) / -1;
  grid-row: 1 / -1;
}

.hiddenDataRowsCaption {
  composes: hiddenDataCaption;
  min-height: var(--hidden-data-caption-min-height);
  grid-column: 1 / -1;
  grid-row: var(--hidden-grid-line-start) / -1;
}

.hiddenDataRowsAndColumns {
  grid-column: 1 / var(--hidden-grid-line-start);
}
