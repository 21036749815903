.root {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &:focus-visible {
    outline: none;

    .innerRoot {
      border-radius: var(--border-radius-6);
      outline: var(--border-width-focus) solid var(--color-focus-default);
    }
  }
}

.innerRoot {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  transition:
    color var(--duration-fast-01) ease-out,
    rotate var(--duration-fast-01) ease-out;

  & > svg {
    position: absolute;
    width: 100%;
    height: 100%;
    inset: 0;
  }
}
