@use '../abstracts/' as *;

.panel {
  --panel-border-radius: var(--border-radius-8);

  border-radius: var(--panel-border-radius);
  background-color: var(--color-bg-surface-1);
}

.largePanel {
  composes: panel;
  --panel-border-radius: var(--border-radius-12);
}

.darkPanel {
  composes: panel;
  background-color: var(--color-bg-surface-3);
}

.largeDarkPanel {
  composes: darkPanel;
  composes: largePanel;
}

.borderedPanel {
  composes: panel;
  border: var(--border-width-1) solid var(--color-border-muted);
}
