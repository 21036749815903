@use '~/styles' as *;

.headerContainer {
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-12);
}

.root {
  composes: headerContainer;
  padding-bottom: var(--spacing-24);
}

.rightContent {
  composes: headerContainer;
  justify-content: flex-end;
}
