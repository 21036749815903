@use '~/styles' as *;

.table {
  td {
    padding: var(--spacing-4);
    vertical-align: top;

    &:first-child {
      padding-left: none;
    }

    &:last-child {
      padding-right: none;
      vertical-align: middle;
    }
  }
}
