@use '~/styles' as *;

.root {
  --index-width: 20px;
  --index-gap: var(--spacing-8);

  display: grid;
  align-items: center;
  padding: var(--spacing-12) var(--spacing-16);
  gap: var(--spacing-4) var(--spacing-8);
  grid-auto-rows: auto;
  grid-template-columns: var(--index-width) 1fr auto;
}

.segmentedTab {
  margin-left: var(--spacing-8);
  grid-column: 3;
}

.baseBlock {
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-column: 2;
}

.mappingSelect {
  display: flex;
  gap: var(--spacing-8);
  grid-column: 2;
  grid-row: 2;
}

.ignoreTitle {
  color: var(--color-fg-disabled);
  text-decoration-line: line-through;
}
