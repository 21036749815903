.rounded-full {
  border-radius: 50% !important;
}

.rounded-8 {
  border-radius: var(--border-radius-8);
}

.shadow-border {
  box-shadow: 0 0 0 1px var(--color-neutral-75);
}
