@use '~/styles' as *;

.infoTable {
  --first-row-width: 140px;

  display: grid;
  margin-top: var(--spacing-8);
  gap: var(--spacing-4) var(--spacing-12);
  grid-template-columns: var(--first-row-width) 1fr;
}
