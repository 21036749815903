@use '~/styles' as *;

.root {
  --description-block-width: 800px;
}

.descriptionBlock {
  max-width: var(--description-block-width);
  margin-bottom: var(--spacing-24);
}

// TODO: make another solution for input width with right feedback
.input > div {
  width: var(--input-width-default);
}
