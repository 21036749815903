@use '~/styles' as *;

.root {
  position: relative;
  padding: var(--spacing-8);
  border: 1px solid var(--color-border-soft);
  margin: var(--spacing-8) 0;

  &:first-child {
    margin: 0;
  }
}
