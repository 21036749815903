@use '~/styles' as *;

.m-toggle-buttons-container {
  display: flex;
  flex-flow: row wrap;
}

.m-toggle-buttons-item {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  padding: var(--spacing-8);
  border: none;
  background-color: var(--color-static-white);
  color: var(--color-fg-soft);

  svg {
    fill: var(--color-fg-soft);
  }

  &:first-child {
    border-bottom-left-radius: var(--spacing-8);
    border-top-left-radius: var(--spacing-8);
  }

  &:last-child {
    border-bottom-right-radius: var(--spacing-8);
    border-top-right-radius: var(--spacing-8);
  }

  &.selected {
    @include field-border;

    z-index: 1;
    border-color: var(--color-accent-default);
    margin: -1px;
    background-color: var(--color-static-white);
    color: var(--color-accent-default);

    svg {
      fill: var(--color-accent-default);
    }
  }
}
