@use '~/styles' as *;

.form {
  display: flex;
  flex-direction: row;
}

.operator {
  flex-shrink: 0;
  margin-right: var(--spacing-8);
}

.editor {
  flex-grow: 1;
  margin-top: var(--spacing-8);
}
