@use '~/styles' as *;

.children {
  overflow: hidden;
  transition: max-height var(--duration-fast-01) ease-out;
}

.contentWrapper {
  padding-bottom: var(--spacing-8);
}
