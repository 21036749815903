@use '~/styles' as *;

.iconContainer {
  --icon-opacity: var(--table-row-content-hovered-opacity);

  position: absolute;
  top: 0;
  right: var(--spacing-16);
  bottom: 0;
  display: grid;
  place-items: center;

  &:has([data-is-context-menu-open='true']) {
    --icon-opacity: 1;
  }
}

.icon {
  opacity: var(--icon-opacity);

  &:focus {
    --icon-opacity: 1;
  }
}
