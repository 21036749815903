@use '~/styles' as *;

.root {
  // Used as a hack for drawing only half of the top chart label
  --chart-shift: var(--spacing-16);

  padding: var(--spacing-24) var(--spacing-24)
    calc(var(--spacing-24) - var(--chart-shift));
}

.chart {
  overflow: hidden;

  // Chart shift, so we clip top label
  & > canvas {
    position: relative;
    top: calc(var(--chart-shift) * -1);
  }

  // Tooltip shift
  & > div {
    translate: 0 calc(var(--chart-shift) * -1);
  }
}
