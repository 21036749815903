@mixin custom-scrollbar-base($containerBackgroundColor) {
  &::-webkit-scrollbar {
    width: var(--scrollbar-size);
    height: var(--scrollbar-size);
  }

  &::-webkit-scrollbar-track {
    background-color: $containerBackgroundColor;
  }

  &::-webkit-scrollbar-thumb {
    border: var(--border-width-2) solid $containerBackgroundColor;
    border-radius: var(--border-radius-8);
    background-color: var(--color-neutral-container-hover);

    @media (hover: hover) {
      // Hide scroll thumb without hover
      background-color: $containerBackgroundColor;
    }
  }
}

@mixin custom-scrollbar-visible-thumb($scrollbarThumbContainerSelector: '&') {
  #{$scrollbarThumbContainerSelector}::-webkit-scrollbar-thumb {
    // Show scroll thumb only on hover over scroll container
    background-color: var(--color-neutral-container-hover);

    @content;
  }
}

@mixin custom-scrollbar-hover($scrollbarThumbContainerSelector: '&') {
  &:hover {
    @include custom-scrollbar-visible-thumb($scrollbarThumbContainerSelector) {
      &:hover {
        // Active color, when hovering thumb itself
        background-color: var(--color-neutral-container-active);
      }
    }
  }
}

@mixin custom-scrollbar($containerBackgroundColor) {
  @include custom-scrollbar-base($containerBackgroundColor);
  @include custom-scrollbar-hover;
}
