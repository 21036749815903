@use '~/styles' as *;

.rootWrapper {
  --field-border-color: transparent;

  display: inline-block;
}

.light {
  --field-background: var(--color-static-white);
}

.dark {
  --field-background: var(--color-neutral-30);
}

.root {
  display: flex;
  height: var(--control-height);
  align-items: center;
  padding: var(--spacing-4) var(--spacing-8);
  border: 1px solid var(--field-border-color);
  border-radius: var(--border-radius-8);
  background-color: var(--field-background);
  cursor: pointer;
  gap: var(--spacing-4);

  &.error {
    --field-border-color: var(--color-error-default);
  }

  &.disabled {
    --field-background: var(--color-neutral-opaque-container-default);
  }

  &:focus-within:not(.disabled, .error) {
    --field-border-color: var(--color-accent-default);
  }
}

.input {
  position: relative;
  display: grid;
  height: 100%;
  place-items: center;

  input {
    position: absolute;
    cursor: pointer;
    inset: 0;
    opacity: 0;
  }
}
