@use '~/styles' as *;

.large24 {
  --badge-block-spacing: var(--spacing-2);
  --badge-inline-spacing: var(--spacing-4);
  --badge-border-radius: var(--border-radius-6);
}

.medium20 {
  --badge-block-spacing: 0;
  --badge-inline-spacing: var(--spacing-4);
  --badge-border-radius: var(--border-radius-4);
}

.small16 {
  --badge-block-spacing: 0;
  --badge-inline-spacing: var(--spacing-2);
  --badge-border-radius: var(--border-radius-4);
}

.root {
  display: inline-flex;
  max-width: min-content;
  padding: var(--badge-block-spacing) var(--badge-inline-spacing);
  border-radius: var(--badge-border-radius);
  white-space: nowrap;
}

.text {
  padding-inline: var(--badge-inline-spacing);
}

.pill {
  --badge-border-radius: var(--border-radius-full);
}
