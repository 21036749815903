@use '~/styles' as *;

.root {
  min-width: fit-content;

  table {
    --basic-border-color: var(--color-border-soft);
    --full-border-color: transparent;

    width: 100%;
    background-color: var(--color-static-white);

    td,
    th {
      border-top: 1px solid var(--full-border-color);
      border-right: 1px solid var(--basic-border-color);
      border-bottom: 1px solid var(--basic-border-color);
      border-left: 1px solid var(--full-border-color);
    }

    th {
      padding: var(--spacing-16) var(--spacing-8);
      font-weight: bold;
      text-align: left;

      &:last-child {
        border-right-color: transparent;
      }
    }

    td {
      position: relative;
      padding: 0;
      vertical-align: middle;

      :global(.m-control) {
        max-width: none;
      }

      &:focus-within {
        --basic-border-color: var(--color-accent-default);
        --full-border-color: var(--color-accent-default);

        // Border immitations for dealing with collapsed borders
        &::before,
        &::after {
          position: absolute;
          background-color: var(--full-border-color);
          content: '';
        }

        &::before {
          top: -1px;
          right: -1px;
          left: -1px;
          height: 1px;
        }

        &::after {
          top: -1px;
          bottom: -1px;
          left: -1px;
          width: 1px;
        }
      }
    }
  }
}
