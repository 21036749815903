@use '~/styles' as *;

.root {
  position: absolute;
  top: var(--spacing-16);
  right: var(--spacing-16);
  display: flex;
  align-items: center;

  *:first-child {
    margin-right: var(--spacing-8);
  }

  *:last-child {
    margin-right: 0;
  }

  svg {
    cursor: pointer;
    fill: var(--color-fg-default);
  }
}
