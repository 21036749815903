@use '~/styles' as *;

.root {
  cursor: pointer;
  user-select: none;

  &.focused .chipButton {
    outline: var(--border-width-focus) solid var(--color-focus-default);
    outline-offset: var(--spacing-2);
  }
}

.chipButton {
  padding: calc(var(--spacing-6) - var(--border-width-1))
    calc(var(--spacing-12) - var(--border-width-1));
  border: var(--border-width-1) solid var(--color-border-soft);
  border-radius: var(--border-radius-full);
  background: var(--color-bg-surface-1);
  color: var(--color-fg-soft);

  &:hover {
    background: var(--color-bg-surface-3);
  }

  &:active {
    border-color: var(--color-border-default);
  }
}

.selected {
  .chipButton {
    border-color: var(--color-accent-muted);
    background: var(--color-accent-opaque-container-default);
  }

  .chipButton:hover {
    background: var(--color-accent-opaque-container-hover);
  }

  .chipButton:active {
    border-color: var(--color-accent-soft);
  }
}
