@use '~/styles' as *;

.large24 {
  --button-border-radius: var(--border-radius-6);
  --button-focus-border-radius: var(--border-radius-8);
}

.medium20 {
  --button-border-radius: var(--border-radius-4);
  --button-focus-border-radius: var(--border-radius-6);
}

.small16 {
  --button-border-radius: var(--border-radius-4);
  --button-focus-border-radius: var(--border-radius-6);
}

.onLight {
  --button-bg: transparent;
  --button-bg-hover: var(--color-neutral-container-hover);
  --button-bg-focused: var(--color-neutral-container-active);
  --button-bg-pressed: var(--color-neutral-container-active);

  --button-fg: var(--color-fg-soft);
}

.onDark {
  --button-bg: transparent;
  --button-bg-hover: var(--color-neutral-700);
  --button-bg-focused: var(--color-neutral-400);
  --button-bg-pressed: var(--color-neutral-800);

  --button-fg: var(--color-neutral-50);
}

.root {
  --button-bg-actual: var(--button-bg);

  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border: none;
  border-radius: var(--button-border-radius);
  background-color: var(--button-bg-actual);
  color: var(--button-fg);
  cursor: pointer;
  outline: none;
  user-select: none;

  &.focused {
    --button-bg-actual: var(--button-bg-focused);

    border-radius: var(--button-focus-border-radius);
    outline: var(--border-width-focus) solid var(--color-focus-default);
    outline-offset: var(--spacing-2);
  }

  &:hover {
    --button-bg-actual: var(--button-bg-hover);
  }

  &.pressed {
    --button-bg-actual: var(--button-bg-pressed);
  }
}
