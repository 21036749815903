@use '~/styles' as *;

.progressBarContainer {
  --progress-bar-height: 6px;

  position: relative;
  width: 150px;
  height: var(--progress-bar-height);
  border-radius: var(--border-radius-full);
  margin-top: var(--spacing-6);
  background-color: var(--color-accent-container-default);
}

.progressBarValue {
  position: absolute;
  height: 100%;
  border-radius: var(--border-radius-full);
  background-color: var(--color-accent-default);
}
