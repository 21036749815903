@use '~/styles' as *;

.root {
  --tooltip-background-color: var(--color-static-white);
  --tooltip-max-width: 272px;

  z-index: var(--z-index-tooltip);
  max-width: var(--tooltip-max-width);
  padding: var(--spacing-8) var(--spacing-12);
  border-radius: var(--border-radius-8);
  background-color: var(--tooltip-background-color);
  box-shadow: var(--shadow-medium);
}

.arrow {
  fill: var(--tooltip-background-color);
}

.tooltipIcon {
  color: var(--color-neutral-muted);
  cursor: help !important;

  &:hover {
    color: var(--color-neutral-hover);
  }
}
