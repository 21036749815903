@use '~/styles' as *;

.info {
  --company-info-text-width: var(--size-28);
  --company-info-width: calc(
    var(--size-20) + var(--spacing-8) + var(--company-info-text-width)
  );

  display: grid;
  margin-top: auto;
  gap: var(--spacing-20) var(--spacing-16);
  grid-template-columns: var(--company-info-width) 1fr;
}
