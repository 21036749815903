.m-print * {
  @media print {
    display: none;
  }
}

.only-for-print {
  display: none;

  @media print {
    display: revert;
  }
}

.m-print *:has(> *.m-print-show) {
  @media print {
    display: table !important;
  }
}
