@use '~/styles' as *;

.root {
  --edit-company-table-columns: var(--edit-company-small-input-width)
    var(--input-width-default) 1fr var(--size-24);

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-12);
}
