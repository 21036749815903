@use '~/styles' as *;

.root {
  width: var(--dot-size);
  min-width: var(--dot-size);
  max-width: var(--dot-size);
  height: var(--dot-size);
  min-height: var(--dot-size);
  max-height: var(--dot-size);
  border-radius: 50%;
}

.small10 {
  --dot-size: 10px;
}

.medium12 {
  --dot-size: 12px;
}

.large14 {
  --dot-size: 14px;
}
