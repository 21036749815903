@use '~/styles' as *;

.inputs {
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-8);
}

.input {
  --select-min-width: auto;

  width: 250px;
}
