@use '~/styles' as *;

.inputSubForm {
  --rows-count: 2;

  display: grid;
  align-items: center;
  margin-bottom: var(--spacing-8);
  gap: 0 var(--spacing-16);
  grid-template-columns: repeat(3, var(--input-width-default));
  grid-template-rows: repeat(var(--rows-count), auto);

  &:has(.input :nth-child(3)) {
    --rows-count: 3;
  }
}

.input {
  grid-row: 1 / span var(--rows-count);
  grid-template-rows: subgrid;
}

.checkbox {
  grid-row: 2;
}

.cowIdTextBlock {
  --cow-id-text-block-width: 76px;

  width: var(--cow-id-text-block-width);
}

.penGroupTextBlock {
  --pen-group-text-block-width: 240px;

  width: var(--pen-group-text-block-width);
}
