@use '~/styles' as *;

.root {
  --field-height: 24px;
  --horizontal-padding: var(--spacing-8);
  --vertical-padding: var(--spacing-6);

  display: flex;
  min-height: calc(var(--field-height) + var(--vertical-padding) * 2);
  align-items: center;
  padding: var(--vertical-padding) var(--horizontal-padding);
  background-color: var(--color-static-white);
  cursor: pointer;

  // Wrapper container already have a padding,
  // but we also need the padding for an individual item for the drag overlay.
  // So we use a margin hack to compensate dublicating padding
  &:not(.dragOverlay) {
    margin: 0 calc(-1 * var(--horizontal-padding));
  }
}

.dragging:not(.dragOverlay) {
  opacity: 0.5;
}
