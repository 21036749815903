@use '~/styles' as *;

.root {
  position: relative;
  padding: var(--spacing-8);

  &:hover {
    svg {
      opacity: 1;
    }
  }
}

.icon {
  @include edit-toggle;
}
