@use '~/styles' as *;

.blockTitle {
  margin-top: var(--spacing-24);
  margin-bottom: var(--spacing-12);

  &:first-child {
    margin-top: 0;
  }
}
