@use '~/styles' as *;

.root {
  z-index: var(--z-index-popover);
  border-radius: var(--border-radius-8);
  background-color: var(--color-static-white);
  box-shadow: var(--shadow-large);

  &:focus {
    outline: none;
  }
}

.cursorPointer {
  cursor: pointer;
}
