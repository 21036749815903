@use '~/styles' as *;

.m-textarea {
  width: 100%;
  padding: var(--spacing-8);
  background-color: transparent;
  resize: vertical;

  @include field-border;
  @include body-small;
}
