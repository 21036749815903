@use '~/styles' as *;

.root {
  --list-border-radius: var(--border-radius-8);

  overflow: hidden;
  padding: var(--spacing-4) 0;
  border: 1px solid var(--color-neutral-50);
  border-radius: var(--border-radius-8);

  &:has(.additionalListItem:first-child) {
    padding-top: 0;
  }

  &:has(.additionalListItem:last-child) {
    padding-bottom: 0;
  }
}

.list {
  overflow: auto;
  min-width: var(--select-popover-min-width);
  max-height: var(--popover-max-height);
  overscroll-behavior: contain;

  @include custom-scrollbar(var(--color-bg-surface-1));

  &:has(.highlighted) {
    @include custom-scrollbar-visible-thumb;
  }
}

.compactList {
  .listItem {
    display: flex;
    min-height: var(--select-item-height);
    align-items: center;
    padding: 0 var(--spacing-16);
  }
}

.multi {
  .interactiveListItem {
    display: grid;
    padding: var(--spacing-8) var(--spacing-16) var(--spacing-8)
      var(--spacing-8);
    gap: 0 var(--spacing-8);
    grid-template-columns: auto 1fr;

    & > :first-child {
      grid-column: 1;
      grid-row: 1 / span 2;
    }
  }
}

.listItem {
  padding: var(--spacing-8) var(--spacing-16);
  background-color: var(--color-static-white);
}

.interactiveListItem {
  composes: listItem;
  cursor: pointer;

  &.selected {
    background: var(--color-accent-container-default);
  }

  &.highlighted,
  &:hover {
    background: var(--color-accent-opaque-container-hover);
  }
}

.additionalListItem {
  composes: listItem;
  display: grid;
  padding: var(--spacing-4);

  &:first-child {
    border-bottom: 1px solid var(--color-border-muted);
    border-top-left-radius: var(--list-border-radius);
    border-top-right-radius: var(--list-border-radius);
  }

  &:last-child {
    border-top: 1px solid var(--color-border-muted);
    border-bottom-left-radius: var(--list-border-radius);
    border-bottom-right-radius: var(--list-border-radius);
  }
}

.listItemContentWrapper {
  width: 100%;
  padding: var(--spacing-2) var(--spacing-6);
  border-radius: var(--border-radius-4);
}

.loader {
  min-height: var(--size-24);
}
