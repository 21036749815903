@use '~/styles' as *;

.root {
  --label-color: var(--color-fg-soft);

  display: flex;
  align-items: center;
  color: var(--label-color);
  white-space: nowrap;
}

.error {
  --label-color: var(--color-error-default);
}

.asterisk {
  color: var(--color-error-default);
}

.tooltip {
  margin-left: var(--spacing-4);
  color: var(--color-fg-muted);

  &:hover {
    color: var(--color-fg-soft);
  }
}
