@use '~/styles' as *;

.root {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--spacing-20) var(--spacing-16);
  border: var(--border-width-1) solid var(--color-border-muted);
  border-radius: var(--border-radius-12);
  background-color: var(--color-bg-surface-1);
}

.moveInfoContent {
  display: flex;
  align-items: center;
  color: var(--color-fg-soft);
  gap: var(--spacing-16);
}
