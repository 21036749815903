@use '../abstracts' as *;

// Colors

.text-error {
  color: var(--color-error-default);
}

.text-success {
  color: var(--color-success-default);
}

.text-warning {
  color: var(--color-warning-default);
}

.text-accent {
  color: var(--color-accent-default);
}

.text-soft {
  color: var(--color-fg-soft);
}

.text-muted {
  color: var(--color-fg-muted);
}

.text-info {
  color: var(--color-info-on-container);
}

.m-hint-svg {
  fill: var(--color-fg-muted);
}

.text-disabled {
  color: var(--color-fg-disabled);
}

// Formatting

.whitespace-nowrap {
  white-space: nowrap;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-underline {
  text-decoration: underline;
}

// Alignment

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

// Lists

// This is used to revert css reset defaults and should be on top of other list classes to avoid revering them
.list-revert {
  list-style: revert;
}

.list-inside {
  list-style-position: inside;
}
