@use '../abstracts/' as *;

.contentGrid {
  display: grid;
  gap: var(--content-grid-gap);
  grid-template-columns: repeat(
    var(--content-grid-columns-count),
    minmax(0, 1fr)
  );
}

.contentGridFrom1To2To4Items {
  composes: contentGrid;

  --items-span: 4;

  & > * {
    grid-column: span var(--items-span);
  }

  @media (--breakpoint-medium-768-up) {
    --items-span: 3;
  }

  @media (--breakpoint-large-1280-up) {
    --items-span: 2;
  }
}

// TODO workout auto grid margins for different breakpoints, so we can use more general auto grid
.contentGridFrom1To2Items {
  composes: contentGridFrom1To2To4Items;

  @media (--breakpoint-large-1280-up) {
    --items-span: 4;
  }
}

.from4to6ColumnsCenteredBlock {
  grid-column: 1 / span 4;

  @media (--breakpoint-medium-768-up) {
    grid-column: 2 / span 4;
  }

  @media (--breakpoint-large-1280-up) {
    grid-column: 2 / span 6;
  }
}

.autoGrid {
  --auto-grid-single-column: minmax(var(--auto-grid-column-width), 1fr);
  --auto-grid-columns: var(--auto-grid-single-column);

  display: grid;
  grid-template-columns: repeat(
    var(--auto-grid-mode),
    var(--auto-grid-columns)
  );
  transition: --auto-grid-column-width 2s ease-out;
}

.autoGridTiles {
  composes: autoGrid;

  --auto-grid-mode: auto-fill;
  --auto-grid-column-width: var(--tile-min-width-medium);

  gap: var(--content-grid-gap);
}

.autoGridTilesMedium {
  composes: autoGridTiles;
}

.autoGridTilesLarge {
  composes: autoGridTiles;

  // You should always apply container-type: inline-size to tiles container for size switching to work correctly
  @container (width > #{$tileMinWidthLarge}) {
    --auto-grid-column-width: var(--tile-min-width-large);
  }
}

.autoGridFixedContent {
  composes: autoGrid;

  --auto-grid-mode: auto-fit;
  --auto-grid-column-width: var(--tile-min-width-medium);

  gap: var(--spacing-16);

  // This will only work inside container-type: inline-size.
  // It makes sure, that grids with even children count adapt by pairs
  &:has(> :nth-child(2n):last-child) {
    @container (width > #{$tileMinWidthMedium * 2 + $spacing16}) {
      --auto-grid-columns: var(--auto-grid-single-column)
        var(--auto-grid-single-column);
    }
  }
}
