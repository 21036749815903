@use '~/styles' as *;

.stickyCell {
  position: sticky;
  z-index: var(--z-index-base);
  left: 0;

  &::before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: -1px;
    width: 1px;
    background: var(--border-color);
    content: '';
  }
}
