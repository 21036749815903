@use '~/styles' as *;

.m-autocomplete {
  @include dropdown-base;

  .m-dropdown-content {
    @include dropdown-content;
  }

  .m-dropdown-list {
    @include dropdown-list;
  }

  .m-dropdown-list-item {
    @include dropdown-list-item;
  }

  input {
    z-index: var(--z-index-old-menu);
    width: 100%;
    min-width: 100px;
    border: none;
    background-color: transparent;
    outline: none;

    @include body-small;
  }

  .m-dropdown-content-selected {
    overflow: hidden;
    flex: 1;
    text-overflow: ellipsis;
    white-space: nowrap;

    & > .m-selector-item {
      margin: 0;
    }
  }
}
