@use '~/styles' as *;

.root {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: space-between;
}

.action {
  @include node-header-action;
}
