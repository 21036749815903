@use '~/styles' as *;

.root {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-24);
}

.activateKeyForm {
  display: flex;
  padding: var(--spacing-16);
  border: var(--border-width-2) solid var(--color-accent-muted);
  border-radius: var(--border-radius-12);
  background-color: var(--color-accent-opaque-container-soft);
  gap: var(--spacing-12);
}

.blockedMessage {
  padding: var(--spacing-24);
  border: var(--border-width-1) solid var(--color-border-muted);
  border-radius: var(--border-radius-8);
}
