@use '~/styles' as *;

.title {
  min-width: 100%;
  max-width: min-content;
}

.tooltipContent {
  grid-gap: var(--spacing-8) var(--spacing-12);
  grid-template-columns: minmax(0, 1fr) repeat(3, auto);
}
