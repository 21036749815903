@use '~/styles' as *;

.table {
  width: 100%;
  min-width: 50vw;
  border-collapse: separate;
  border-spacing: 0;

  thead {
    position: sticky;
    top: 0;
    height: 40px;

    tr {
      background-color: var(--color-neutral-30);
      color: var(--color-fg-default);
    }

    th {
      padding: var(--spacing-8);
      border-bottom: 2px solid var(--color-border-soft) !important;
      font-weight: 600;
      text-align: left;

      &:first-child {
        padding-left: var(--spacing-24);
      }
    }
  }

  tr {
    &:last-child {
      border-bottom: none;
    }
  }

  td {
    padding: var(--spacing-8);
    border-bottom: 1px solid var(--color-border-soft);

    &.shift:first-child {
      padding-left: var(--spacing-24);
    }
  }
}
