@use '../abstracts/' as *;

.customScrollBaseContainer {
  @include custom-scrollbar-base(var(--color-bg-surface-1));
}

.customScrollHoverContainer {
  // Sticky styles won't work in a nested overflow container, so we shouldn't apply any header shift
  --sticky-header-top-current: 0px;

  @include custom-scrollbar-hover('.customScrollBaseContainer');
}

.customScrollStickyContainer {
  position: sticky;
  z-index: var(--z-index-sticky-scrollbar);
  bottom: 0;
}

.customScrollContainer {
  @include custom-scrollbar-base(var(--color-bg-surface-1));
  @include custom-scrollbar-hover;
}
