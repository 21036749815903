@use '~/styles' as *;

.large48 {
  --button-horizontal-padding: var(--spacing-16);
  --button-icon-padding: var(--spacing-4);
  --button-border-radius: var(--border-radius-8);
  --button-focus-border-radius: var(--border-radius-10);
}

.medium36 {
  --button-horizontal-padding: var(--spacing-12);
  --button-icon-padding: var(--spacing-2);
  --button-border-radius: var(--border-radius-8);
  --button-focus-border-radius: var(--border-radius-10);
}

.small24 {
  --button-horizontal-padding: var(--spacing-8);
  --button-icon-padding: var(--spacing-2);
  --button-border-radius: var(--border-radius-6);
  --button-focus-border-radius: var(--border-radius-8);
}

.primary {
  &.accent {
    --button-bg: var(--color-accent-default);
    --button-bg-active: var(--color-accent-hover);
    --button-bg-pressed: var(--color-accent-active);
    --button-bg-disabled: var(--color-neutral-container-soft);

    --button-fg: var(--color-accent-on-accent);
    --button-fg-active: var(--color-accent-on-accent);
    --button-fg-disabled: var(--color-fg-disabled);
  }

  &.neutral {
    --button-bg: var(--color-neutral-default);
    --button-bg-active: var(--color-neutral-hover);
    --button-bg-pressed: var(--color-neutral-active);
    --button-bg-disabled: var(--color-neutral-container-soft);

    --button-fg: var(--color-neutral-on-neutral);
    --button-fg-active: var(--color-neutral-on-neutral);
    --button-fg-disabled: var(--color-fg-disabled);
  }

  &.destructive {
    --button-bg: var(--color-error-default);
    --button-bg-active: var(--color-error-hover);
    --button-bg-pressed: var(--color-error-active);
    --button-bg-disabled: var(--color-error-container-soft);

    --button-fg: var(--color-error-on-error);
    --button-fg-active: var(--color-error-on-error);
    --button-fg-disabled: var(--color-error-container-active);
  }
}

.secondary {
  &.accent {
    --button-bg: var(--color-accent-container-default);
    --button-bg-active: var(--color-accent-container-hover);
    --button-bg-pressed: var(--color-accent-container-active);
    --button-bg-disabled: var(--color-neutral-container-soft);

    --button-fg: var(--color-accent-on-container);
    --button-fg-active: var(--color-accent-hover);
    --button-fg-disabled: var(--color-fg-disabled);
  }

  &.neutral {
    --button-bg: var(--color-neutral-container-default);
    --button-bg-active: var(--color-neutral-container-hover);
    --button-bg-pressed: var(--color-neutral-container-active);
    --button-bg-disabled: var(--color-neutral-container-soft);

    --button-fg: var(--color-neutral-on-container);
    --button-fg-active: var(--color-neutral-hover);
    --button-fg-disabled: var(--color-fg-disabled);
  }

  &.destructive {
    --button-bg: var(--color-error-container-default);
    --button-bg-active: var(--color-error-container-hover);
    --button-bg-pressed: var(--color-error-container-active);
    --button-bg-disabled: var(--color-error-container-soft);

    --button-fg: var(--color-error-on-container);
    --button-fg-active: var(--color-error-hover);
    --button-fg-disabled: var(--color-error-container-active);
  }
}

.ghost {
  --button-bg: transparent;
  --button-bg-disabled: transparent;

  &.accent {
    --button-bg-active: var(--color-accent-container-default);
    --button-bg-pressed: var(--color-accent-container-active);

    --button-fg: var(--color-accent-on-container);
    --button-fg-active: var(--color-accent-hover);
    --button-fg-disabled: var(--color-fg-disabled);
  }

  &.neutral {
    --button-bg-active: var(--color-neutral-container-default);
    --button-bg-pressed: var(--color-neutral-container-active);

    --button-fg: var(--color-neutral-on-container);
    --button-fg-active: var(--color-neutral-hover);
    --button-fg-disabled: var(--color-fg-disabled);
  }

  &.destructive {
    --button-bg-active: var(--color-error-container-default);
    --button-bg-pressed: var(--color-error-container-active);

    --button-fg: var(--color-error-on-container);
    --button-fg-active: var(--color-error-hover);
    --button-fg-disabled: var(--color-error-container-active);
  }
}

.root {
  --button-bg-actual: var(--button-bg);
  --button-fg-actual: var(--button-fg);

  position: relative;
  display: flex;
  height: var(--button-height);
  align-items: center;
  justify-content: center;
  padding: 0 var(--button-horizontal-padding);
  border: none;
  border-radius: var(--button-border-radius);
  background-color: var(--button-bg-actual);
  color: var(--button-fg-actual);
  cursor: pointer;
  outline: none;
  user-select: none;
  white-space: nowrap;

  &.focused {
    --button-bg-actual: var(--button-bg-active);
    --button-fg-actual: var(--button-fg-active);

    border-radius: var(--button-focus-border-radius);
    outline: var(--border-width-focus) solid var(--color-focus-default);
    outline-offset: var(--spacing-2);
  }

  &:hover:where(:not(.loading)) {
    --button-bg-actual: var(--button-bg-active);
    --button-fg-actual: var(--button-fg-active);
  }

  &.pressed {
    --button-bg-actual: var(--button-bg-pressed);
  }

  &.loading {
    cursor: default;

    .icon,
    .children {
      opacity: 0;
    }
  }

  &.disabled {
    --button-bg-actual: var(--button-bg-disabled);
    --button-fg-actual: var(--button-fg-disabled);

    cursor: not-allowed;
  }
}

.withOnlyIcon {
  &.large48 {
    --button-horizontal-padding: var(--spacing-12);
  }

  &.medium36 {
    --button-horizontal-padding: var(--spacing-8);
  }

  &.small24 {
    --button-horizontal-padding: var(--spacing-4);
  }
}

.root:not(.withOnlyIcon) .icon {
  margin-right: var(--button-icon-padding);
}

.children {
  position: relative;
  z-index: calc(var(--z-index-base) + 1);
  display: flex;
  align-items: center;
  padding: 0 var(--spacing-4);
}

.loader {
  position: absolute;
  inset: 0;
}
