@use '~/styles' as *;

.root {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--spacing-12) var(--spacing-16);

  &:hover {
    .hoveredDeleteIcon {
      opacity: 1;
    }
  }

  .hoveredDeleteIcon {
    opacity: 0;

    &:focus-visible {
      opacity: 1;
    }
  }
}
