@use '~/styles' as *;

.keyContent {
  display: flex;
  flex-direction: column;
  padding: var(--spacing-16);
  border: var(--border-width-1) solid var(--color-border-muted);
  border-radius: var(--border-radius-8);
  gap: var(--spacing-16);
}
