@use '~/styles' as *;

.root {
  --border-color: transparent;
  --input-height: var(--control-height);

  gap: var(--spacing-4);

  &.withRightFeedback {
    align-items: center;
    gap: var(--spacing-12);
    grid-auto-flow: column;
  }
}

.unstyled {
  .inputContainer {
    display: contents;
  }
}

.basic {
  composes: unstyled;
  display: grid;

  .inputContainer {
    display: flex;
    height: var(--input-height);
    align-items: center;
    padding: 0 var(--spacing-8);
    background-color: var(--background-color);
  }
}

.styled {
  composes: basic;

  &.error {
    --border-color: var(--color-error-default);
  }

  &.disabled {
    --background-color: var(--color-neutral-opaque-container-default);
  }

  &:focus-within:not(.disabled, .error) {
    --border-color: var(--color-accent-default);

    .addonAfter {
      color: var(--color-accent-default);
    }
  }

  .inputContainer {
    border: 1px solid;
    border-color: var(--border-color);
    border-radius: var(--border-radius-8);
  }
}

.light,
.dark {
  composes: styled;
}

.light {
  --background-color: var(--color-static-white);
}

.dark {
  --background-color: var(--color-neutral-30);
}

.input {
  width: 100%;
  height: 100%;
  background: transparent;
  outline: none;

  &:placeholder-shown {
    text-overflow: ellipsis;
  }

  &::placeholder {
    color: var(--color-fg-muted);

    @include body-small;
  }
}
