@use '~/styles' as *;

.root {
  padding: 75px 122px 74px;
  animation: --keyframes-pulse var(--duration-pulse) var(--easing-pulse)
    infinite;
  color: var(--color-neutral-container-default);
}

@keyframes --keyframes-pulse {
  50% {
    color: var(--color-neutral-container-soft);
  }
}
