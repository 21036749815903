@use '~/styles' as *;

:root {
  --select-popover-min-width: 108px;
  --select-height: var(--control-height);
  --select-min-width: 1px;
  --select-item-height: 36px;

  --select-optimistic-color: var(--color-static-white);
  --select-default-color: var(--color-static-white);
  --select-hover-color: var(--color-bg-surface-2);
  --select-active-color: var(--color-static-white);
}

.root {
  display: grid;
  gap: var(--spacing-4);
}

.basic {
  position: relative;
  display: flex;
  height: var(--select-height);
  padding: var(--spacing-8);
}

.disabled,
.disabled .input,
.disabled svg {
  color: var(--color-neutral-200);
  cursor: not-allowed;
}

.styled {
  --select-background-color: var(--select-default-color);

  composes: basic;
  border-radius: var(--select-border-radius);
  background-color: var(--select-background-color);
  outline: none;

  &:hover {
    --select-background-color: var(--select-hover-color);
  }

  &:focus-within:where(:not(.disabled, .wasUpdated)),
  &.open {
    --select-background-color: var(--select-active-color);
  }

  &.optimistic {
    --select-background-color: var(--select-optimistic-color);
  }
}

.light,
.dark {
  composes: styled;
}

.dark {
  --select-optimistic-color: var(--color-neutral-container-muted);
  --select-default-color: var(--color-neutral-container-muted);
  --select-hover-color: var(--color-neutral-container-soft);
  --select-active-color: var(--color-neutral-container-muted);
}

.withBorder.styled {
  --border-color: transparent;

  border: 1px solid var(--border-color);

  &.error {
    --border-color: var(--color-error-default);
  }

  &:focus-within:not(.disabled, .error, .wasUpdated) {
    --border-color: var(--color-accent-default);
  }
}

.full,
.withItemsList {
  composes: withBorder;
  --select-border-radius: var(--border-radius-8);
}

.compact {
  --select-height: 24px;
  --select-border-radius: var(--border-radius-4);

  --select-optimistic-color: var(--color-neutral-container-soft);
  --select-default-color: var(--color-neutral-container-default);
  --select-hover-color: var(--color-neutral-container-hover);
  --select-active-color: var(--color-neutral-container-active);

  padding: 0 0 0 6px;
  cursor: pointer;

  .buttons {
    color: var(--color-fg-soft);
  }
}

.noTransition {
  transition-duration: revert;
}

.popupSearch {
  --select-background-color: transparent !important;

  .listReference {
    z-index: calc(var(--z-index-base) + 1);
  }

  .hiddenInput {
    z-index: var(--z-index-always-below);
  }
}

.content {
  display: flex;
  width: var(--select-min-width);
  height: 100%;
  flex: 1 1 var(--select-min-width);
  align-items: center;
  justify-content: space-between;
}

.listReference {
  position: absolute;
  z-index: var(--z-index-always-below);
  inset: 0;
}

.buttons {
  display: flex;
  align-items: center;
  margin-left: var(--spacing-8);
  color: var(--color-accent-default);
}

.input {
  position: absolute;
  padding: 0 var(--spacing-8);
  inset: 0;

  &::placeholder {
    color: var(--color-fg-muted);

    @include body-small;
  }
}

.hiddenInput {
  composes: input;
  z-index: var(--z-index-base);
  cursor: pointer;
  opacity: 0;

  input {
    cursor: pointer;
  }
}

.selectedItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--spacing-8);
  border-radius: var(--border-radius-6);
  background-color: var(--color-accent-container-default);
}
