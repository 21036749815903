@use '~/styles' as *;

.info {
  --notification-default-color: var(--color-info-default);
  --notification-light-container-color: var(
    --color-info-opaque-container-default
  );
  --notification-dark-container-color: var(--color-info-soft);
  --notification-border-color: var(--color-info-container-default);
}

.success {
  --notification-default-color: var(--color-success-default);
  --notification-light-container-color: var(
    --color-success-opaque-container-default
  );
  --notification-dark-container-color: var(--color-success-soft);
  --notification-border-color: var(--color-success-container-default);
}

.warning {
  --notification-default-color: var(--color-warning-default);
  --notification-light-container-color: var(
    --color-warning-opaque-container-default
  );
  --notification-dark-container-color: var(--color-warning-soft);
  --notification-border-color: var(--color-warning-container-default);
}

.error {
  --notification-default-color: var(--color-error-default);
  --notification-light-container-color: var(
    --color-error-opaque-container-default
  );
  --notification-dark-container-color: var(--color-error-soft);
  --notification-border-color: var(--color-error-container-default);
}

.neutral {
  --notification-default-color: var(--color-neutral-default);
  --notification-light-container-color: var(
    --color-neutral-opaque-container-default
  );
  --notification-dark-container-color: var(--color-neutral-soft);
  --notification-border-color: var(--color-neutral-container-default);
}
