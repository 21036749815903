@use '~/styles' as *;

.root {
  --section-width: 367px;

  display: flex;
  overflow: auto;
  width: var(--section-width);
  max-height: 100cqh;
  flex-direction: column;
  align-items: center;
  padding: var(--spacing-24) var(--spacing-16);
}
