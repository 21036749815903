@use '~/styles' as *;

.root {
  display: grid;
  grid-template-rows: auto 1fr;
}

// TODO remove hack, when we rework page layout
:global(.m-application-shell-container):has(.root) {
  overflow: hidden;
}
