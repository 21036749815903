@use '~/styles' as *;

.m-breadcrumbs {
  margin: var(--spacing-16) 0;

  span {
    margin: 0 var(--spacing-8);
  }
}

.m-breadcrumbs-item {
  color: var(--color-fg-default);
  text-decoration: none;

  &.active {
    font-weight: bold;
  }
}
