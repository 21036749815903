@use '~/styles' as *;

.children {
  overflow: clip;
  overflow-clip-margin: var(--spacing-4);
  transition: max-height var(--duration-fast-01) ease-out;
}

.itemContainer {
  --container-padding: var(--spacing-16);

  padding-right: calc(var(--container-padding) - var(--scrollbar-size));
  padding-left: var(--container-padding);
}
