@use '~/styles' as *;

.header {
  position: relative;
  display: flex;
  padding: var(--spacing-16);
  cursor: pointer;
  user-select: none;
}

.title {
  flex: 1;
}

.children {
  position: relative;
  overflow: hidden;
  padding: 0 var(--spacing-16);
  transition:
    max-height var(--duration-fast-01) ease-out,
    padding-bottom var(--duration-fast-01) ease-out;
}

.open {
  .children {
    padding: 0 var(--spacing-16) var(--spacing-16) var(--spacing-16);
  }
}
