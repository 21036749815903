@use '~/styles' as *;

.root {
  --bar-height: 16px;
}

.bar {
  display: flex;
  overflow: hidden;
  height: var(--bar-height);
  border-radius: var(--border-radius-4);
}
