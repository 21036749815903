@use '~/styles' as *;

.m-panel {
  position: relative;
  padding: var(--spacing-8);
  border-radius: var(--spacing-8);
  background-color: var(--color-static-white);
  color: var(--color-fg-default);

  &.elevation {
    box-shadow: var(--shadow-large);
  }

  &.secondary {
    background-color: var(--color-neutral-30);
  }

  &:not(.no-panel) .m-control {
    background-color: var(--color-neutral-30) !important;
  }
}
