@use '~/styles' as *;

.root {
  --min-page-height: 800px;
  --content-width: 540px;

  position: relative;
  display: flex;
  height: 100dvh;
  min-height: var(--min-page-height);
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--color-bg-page);
}

.mainBlock {
  display: flex;
  width: var(--content-width);
  flex-direction: column;
  margin-bottom: var(--spacing-128);
  gap: var(--spacing-48);
}

.wavesImage {
  position: absolute;
  bottom: 0;
  width: 100%;
}
