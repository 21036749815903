@use '~/styles' as *;

.root {
  --menu-vertical-padding: var(--spacing-16);

  display: grid;
  overflow: hidden;
  height: 100dvh;
  padding: var(--menu-vertical-padding) 0;
  background: var(--color-bg-surface-2);
  grid-template-columns: 100%;
  grid-template-rows: auto auto 1fr auto;
}

.brand {
  // Variables are passed from the BaseLayout component
  opacity: var(--collapsible-content-opacity);
  transition: opacity var(--duration-medium-02) ease-in-out;
}

.cowSearch {
  padding: var(--spacing-16);
}

.menuItems {
  position: relative;
  overflow: hidden scroll;

  // Padding to avoid clipping of focus states
  padding: var(--spacing-2) 0;
  border-top: var(--border-width-1) solid transparent;
  border-bottom: var(--border-width-1) solid transparent;
  overscroll-behavior: contain;

  @include custom-scrollbar(var(--color-bg-surface-2));
}

.overflowMenuItems {
  animation:
    --keyframes-top-scroll-border step-end,
    --keyframes-bottom-scroll-border step-end reverse;
  animation-timeline: scroll(self);
}

@keyframes --keyframes-top-scroll-border {
  0%,
  0.1% {
    border-top-color: transparent;
  }

  0.5%,
  100% {
    border-top-color: var(--color-border-muted);
  }
}

@keyframes --keyframes-bottom-scroll-border {
  0%,
  0.1% {
    border-bottom-color: transparent;
  }

  0.5%,
  100% {
    border-bottom-color: var(--color-border-muted);
  }
}

.bottomPanel {
  display: flex;
  flex-direction: column;
  padding-top: var(--spacing-8);
  gap: var(--spacing-2);
}

.showForCollapsed {
  opacity: calc(1 - var(--collapsible-content-opacity));
  transition: opacity var(--duration-medium-02) ease-in-out;
}

@media (--breakpoint-medium-768-up) {
  .root {
    --menu-vertical-padding: var(--spacing-24);
  }

  .cowSearch {
    padding-top: var(--spacing-20);
  }
}
