@use '~/styles' as *;

.details {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.main {
  flex-grow: 1;
  margin-right: 10%;
}

.form {
  margin-bottom: var(--spacing-40);
}

.aside {
  width: 30%;
  min-width: 316px;
  padding: var(--spacing-16);
  border-radius: var(--border-radius-8);
  background: var(--color-info-50);
}

.submit {
  margin-top: var(--spacing-24);
  float: right;
}
