@use '~/styles' as *;

.root {
  @include blueprint-edit-section(var(--color-status04-soft));

  width: 38%;
}

.sectionItems {
  height: 300px;
  flex-grow: 1;
  overflow-y: auto;

  @include custom-scrollbar(var(--color-bg-surface-1));
}

.controls {
  display: flex;
  flex-direction: row;

  .button {
    flex-grow: 1;
    margin: var(--spacing-4);
  }
}
