@use '~/styles' as *;

.chartContainer {
  --filter-width: 181px;
  --cow-chart-height: 335px;

  grid-template-rows: auto var(--cow-chart-height);
}

.filter {
  grid-template-columns: var(--filter-width);
}
