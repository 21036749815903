@use '~/styles' as *;

.inner {
  border-radius: var(--border-radius-4);
  animation: --keyframes-pulse var(--duration-pulse) var(--easing-pulse)
    infinite;
  background: var(--color-neutral-container-default);
}

@keyframes --keyframes-pulse {
  50% {
    background: var(--color-neutral-container-soft);
  }
}
