@use '~/styles' as *;

.root {
  --border-color: transparent;
  --background-color: var(--color-neutral-30);

  display: grid;
  gap: var(--spacing-4);

  &:focus-within {
    --border-color: var(--color-accent-default);
  }

  &.error {
    --border-color: var(--color-error-default);
  }

  &.disabled {
    --background-color: var(--color-neutral-opaque-container-default);
  }
}

.light {
  --background-color: var(--color-static-white);
}

.dark {
  --background-color: var(--color-neutral-30);
}

.inputContainer {
  position: relative;
  display: flex;
  height: var(--control-height);
  padding: 0 var(--spacing-8);
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius-8);
  background-color: var(--background-color);
  cursor: text;
  user-select: none;
}

.input {
  position: absolute;
  z-index: var(--z-index-always-below);
  inset: 0;
  pointer-events: none;
}
