/**
 * Do not edit directly
 * Generated on Mon, 03 Feb 2025 08:55:05 GMT
 */

@mixin body-large {
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  font-variant-numeric: lining-nums tabular-nums;
}

@mixin body-large-strong {
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  font-variant-numeric: lining-nums tabular-nums;
}

@mixin body-medium {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  font-variant-numeric: lining-nums tabular-nums;
}

@mixin body-medium-strong {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  font-variant-numeric: lining-nums tabular-nums;
}

@mixin body-small {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  font-variant-numeric: lining-nums tabular-nums;
}

@mixin body-small-strong {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  font-variant-numeric: lining-nums tabular-nums;
}

@mixin description-large {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  font-variant-numeric: lining-nums tabular-nums;
}

@mixin description-large-strong {
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  font-variant-numeric: lining-nums tabular-nums;
}

@mixin description-medium {
  font-size: 11px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.2px;
  font-variant-numeric: lining-nums tabular-nums;
}

@mixin description-medium-strong {
  font-size: 11px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.2px;
  font-variant-numeric: lining-nums tabular-nums;
}

@mixin description-small {
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0.4px;
  font-variant-numeric: lining-nums tabular-nums;
}

@mixin description-small-strong {
  font-size: 10px;
  font-weight: 500;
  line-height: 12px;
  letter-spacing: 0.4px;
  font-variant-numeric: lining-nums tabular-nums;
}

@mixin display-large {
  font-size: 48px;
  font-weight: 500;
  line-height: 52px;
  font-variant-numeric: lining-nums tabular-nums;
}

@mixin display-large-strong {
  font-size: 48px;
  font-weight: 700;
  line-height: 52px;
  font-variant-numeric: lining-nums tabular-nums;
}

@mixin display-medium {
  font-size: 42px;
  font-weight: 500;
  line-height: 44px;
  font-variant-numeric: lining-nums tabular-nums;
}

@mixin display-medium-strong {
  font-size: 42px;
  font-weight: 700;
  line-height: 44px;
  font-variant-numeric: lining-nums tabular-nums;
}

@mixin display-small {
  font-size: 32px;
  font-weight: 500;
  line-height: 44px;
  font-variant-numeric: lining-nums tabular-nums;
}

@mixin display-small-strong {
  font-size: 32px;
  font-weight: 700;
  line-height: 44px;
  font-variant-numeric: lining-nums tabular-nums;
}

@mixin heading-1 {
  font-size: 28px;
  font-weight: 700;
  line-height: 32px;
}

@mixin heading-2 {
  font-size: 22px;
  font-weight: 700;
  line-height: 24px;
}

@mixin heading-3 {
  font-size: 18px;
  font-weight: 700;
  line-height: 20px;
}

@mixin heading-4 {
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
}

@mixin heading-5 {
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
}
