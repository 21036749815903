@use '~/styles' as *;

.root {
  --edit-company-table-columns: repeat(
      3,
      var(--edit-company-medium-input-width)
    )
    var(--edit-company-extra-large-input-width)
    repeat(2, var(--edit-company-large-input-width)) var(--size-24);

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-12);
}
