@use '~/styles' as *;

@mixin last-row-selector {
  .row:not(.expandedRow):has(+ .expandableRow:last-child),
  .row:not(.headerRow):last-child {
    @content;
  }
}

.root {
  --row-height: 36px;
  --expandable-cell-width: 36px;
  --data-blocked-message-width: 520px;
  --border-width: var(--border-width-1);
  --border-color: var(--color-border-muted);

  --base-cell-padding: var(--spacing-12);
  --large-cell-padding: var(--spacing-16);
  --table-cell-padding: var(--spacing-8) var(--base-cell-padding);
  --table-cell-background: var(--color-static-white);

  --corners-border-radius: 0;

  --table-header-z-index: calc(var(--z-index-base) + 10);

  display: grid;
  border-radius: var(--corners-border-radius);
  grid-template-rows: auto auto;

  @include last-row-selector {
    & > .cell:first-child {
      border-bottom-left-radius: var(--corners-border-radius);
    }

    & > .cell:last-child {
      border-bottom-right-radius: var(--corners-border-radius);
    }
  }

  .thead,
  .tbody {
    display: grid;
    grid-column: 1 / -1;
    grid-template-columns: subgrid;
  }

  .thead {
    grid-row: 1;
  }

  .tbody {
    grid-row: 2;
  }
}

.withBorder {
  border: var(--border-width) solid var(--border-color);
}

.baseSecondary {
  --table-cell-padding: var(--spacing-8) 0;
  --border-horizontal-color: var(--border-color);
  --border-vertical-color: var(--border-color);

  .cell {
    border-bottom: var(--border-width) solid var(--border-horizontal-color);
  }

  .cell:has(+ .groupedCellLeft) {
    border-right: var(--border-width) solid var(--border-vertical-color);
  }

  .groupedCellRight:not(:has(+ :is(.fillerCell, .groupedCellLeft))) {
    border-right: var(--border-width) solid var(--border-vertical-color);
  }

  @include last-row-selector {
    & > .cell {
      --border-horizontal-color: transparent;
    }
  }
}

.tertiary {
  --border-color: var(--color-neutral-75);

  .cell {
    border-right: var(--border-width) solid var(--border-color);
    border-bottom: var(--border-width) solid var(--border-color);

    &:first-child {
      border-left: var(--border-width) solid var(--border-color);
    }
  }

  .headerCell {
    border-top: none;
    background-color: var(--color-neutral-30);
  }

  &.withStickyHeader {
    .headerCell::before {
      position: absolute;
      top: 0;
      bottom: 0;
      left: calc(-1 * var(--border-width));
      display: block;
      width: var(--border-width);
      background-color: var(--border-color);
      content: '';
    }
  }
}

.largeSecondary {
  composes: baseSecondary;

  --table-cell-padding: var(--spacing-8) var(--base-cell-padding);

  .headerRow,
  .headerCell {
    background: var(--color-neutral-opaque-container-muted);
  }
}

.smallSecondary {
  --table-header-z-index: calc(var(--z-index-base) + 5);

  composes: largeSecondary;
}

.withStickyHeader {
  .thead {
    position: sticky;
    z-index: var(--table-header-z-index);
    top: var(--sticky-header-top-current);
  }
}

.row {
  --table-row-content-hovered-opacity: 0;

  display: grid;
  grid-column: 1 / -1;
  grid-template-columns: subgrid;

  &.clickable:hover {
    background-color: var(--color-neutral-30);
    cursor: pointer;
  }

  &:hover {
    --table-row-content-hovered-opacity: 1;
  }

  &:not(:is(.expandableRow, .headerRow)) {
    // We should add 1px for the border, cause we have border-box model
    min-height: calc(var(--row-height) + 1px);
  }
}

.headerRow {
  composes: row;
  min-height: var(--row-height);
  grid-row: 1 / 3;
  grid-template-rows: subgrid;
}

.secondHeaderRow {
  composes: headerRow;
  grid-row: 2;
}

.headerRow:not(.headerRow + .headerRow) {
  border-radius: var(--corners-border-radius) var(--corners-border-radius) 0 0;

  .cell:first-child {
    border-top-left-radius: var(--corners-border-radius);
  }

  .cell:has(+ .fillerCell:last-child),
  .cell:last-child {
    border-top-right-radius: var(--corners-border-radius);
  }
}

.cell {
  display: grid;
  height: 100%;
  min-height: var(--row-height);
  align-items: center;
  padding: var(--table-cell-padding);
  grid-template-columns: subgrid;

  & > * {
    grid-column: 1 / -1;
  }

  &:has(.cellTooltip) {
    padding: 0;
  }
}

.cellWithBackground {
  background-color: var(--table-cell-background);
}

.fillerCell {
  padding: 0;
}

.withRoundedPanelCorners {
  --corners-border-radius: var(--border-radius-8);

  &.smallSecondary {
    --corners-border-radius: var(--border-radius-6);
  }
}

.cellTooltip {
  padding: var(--table-cell-padding);
}

.headerCell {
  composes: cell;
  position: relative;
  text-align: left;
  text-wrap: balance;

  &:hover {
    .icon {
      opacity: 1;
    }
  }
}

.stickyCell {
  position: sticky;
  z-index: calc(var(--table-header-z-index) - 1);
  left: 0;
  border-right: var(--border-width-1) solid var(--color-border-muted);
}

.icon {
  position: absolute;
  top: var(--spacing-6);
  right: var(--spacing-8);
  opacity: 0;

  &:focus-within {
    opacity: 1;
  }
}

.itemActionCell {
  composes: cell;

  & > * {
    opacity: var(--table-row-content-hovered-opacity);
  }

  &.headerCell > *,
  &:focus-within > * {
    opacity: 1;
  }
}

.expandedRow {
  --table-cell-background: var(--color-neutral-opaque-container-soft);
}

.expandableRow {
  composes: row;

  & > td {
    display: grid;
    grid-column: 1 / -1;
    grid-template-columns: subgrid;
  }
}

.expandableCell {
  composes: cell;
  width: var(--expandable-cell-width);

  --table-cell-padding: var(--spacing-4);

  & + .stickyCell {
    left: var(--expandable-cell-width);
  }

  &:has(+ .stickyCell) {
    position: sticky;
    z-index: calc(var(--table-header-z-index) - 1);
    left: 0;
  }
}

.expandedCell {
  composes: cell;
  --table-cell-padding: 0;
  --table-cell-background: var(--color-neutral-opaque-container-muted);
}

.itemAction {
  display: flex;
  height: 100%;
  align-items: flex-start;
  justify-content: flex-end;
}

.blockedMessage {
  max-width: var(--data-blocked-message-width);
  padding: var(--spacing-24);
  margin: 0 auto;
}

.nestedTable {
  display: grid;
  width: 100%;
  border: none;
  background: transparent !important;
  grid-column: 1 / -1;
  grid-template-columns: subgrid !important;
  --table-cell-background: transparent !important;
  --corners-border-radius: 0;

  .thead {
    display: none;
  }
}

.collapsibleRowContent {
  display: grid;
  overflow: hidden;
  grid-column: 1 / -1;
  grid-template-columns: subgrid;
  transition: max-height var(--duration-fast-01) ease-out;
}

.collapsibleChildrenContainer {
  display: grid;
  grid-column: 1 / -1;
  grid-template-columns: subgrid;
}

.collapsibleChildrenContainerWithRenderContent {
  composes: collapsibleChildrenContainer;
  padding: var(--spacing-8) var(--large-cell-padding) var(--large-cell-padding)
    calc(var(--expandable-cell-width) + var(--large-cell-padding));
  background: var(--color-neutral-opaque-container-muted);
}
