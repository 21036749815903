@use '../../__generated__/typography-mixins.scss' as *;
@use './fields' as *;
@use './custom-scrollbar' as *;

@mixin dropdown-base {
  position: relative;

  &.disabled {
    background-color: var(--color-neutral-opaque-container-default);
  }

  &.mControl,
  &.m-control {
    @include field-border;
    @include body-small;

    padding: var(--spacing-8);
  }
}

@mixin dropdown-content {
  display: flex;
  height: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  .m-dropdown-content-toggle {
    display: flex;
    align-items: center;
    margin-left: var(--spacing-8);

    svg {
      fill: var(--color-accent-default);
      pointer-events: all;
    }
  }
}

@mixin dropdown-list {
  position: absolute;
  z-index: var(--z-index-old-menu);
  overflow: hidden;
  height: 0;
  padding: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  cursor: default;
  list-style: none;

  @include custom-scrollbar(var(--color-bg-surface-1));

  &.opened {
    left: 0;
    overflow: auto;
    min-width: 100%;
    height: auto;
    max-height: 50vh;
    padding: var(--spacing-4);
    margin-top: calc(var(--spacing-8) + 2 * var(--border-width-1));
  }
}

@mixin dropdown-list-item {
  padding: var(--spacing-4);

  &:hover,
  &.selected,
  &.highlighted {
    background-color: var(--color-accent-opaque-container-default);
  }
}
