@use '~/styles' as *;

.dot {
  animation: --keyframes-dot 1.5s infinite linear;
  opacity: 0;

  &:nth-child(2) {
    animation-delay: 0.33s;
  }

  &:nth-child(3) {
    animation-delay: 0.66s;
  }
}

@keyframes --keyframes-dot {
  0% {
    opacity: 0;
  }

  33%,
  66% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
