@use '~/styles' as *;

.root {
  position: relative;
  min-width: 200px;
  border-radius: 6px;
  margin: var(--spacing-8) 0;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.header {
  display: flex;
  width: 100%;
  padding: var(--spacing-4);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.icon {
  align-self: center;
  margin: 0 var(--spacing-8);
  fill: var(--color-fg-soft);
}

.content {
  border: 1px solid #e1e1e3;
  transition: 0.3s;

  :global(.m-control) {
    background-color: var(--color-neutral-30) !important;
  }
}

.collapsed {
  overflow: hidden;
  height: 0;
  padding: 0;
}
