@use '~/styles' as *;

.root {
  --link-background-color: transparent;

  display: block;
  padding: var(--spacing-2) 0;
  color: var(--color-fg-soft);
  cursor: pointer;
  outline: none;
  user-select: none;

  &:hover {
    --link-background-color: var(--color-neutral-container-soft);
  }

  &.focused {
    --link-background-color: var(--color-neutral-container-soft);

    .link {
      outline: var(--border-width-focus) solid var(--color-focus-default);
      outline-offset: var(--spacing-2);
    }
  }

  &.pressed {
    --link-background-color: var(--color-neutral-container-default);
  }

  &.active {
    --link-background-color: var(--color-accent-container-default);

    color: var(--color-accent-on-container);

    &:hover {
      --link-background-color: var(--color-accent-container-hover);
    }

    &.focused {
      --link-background-color: var(--color-accent-container-hover);
    }

    &.pressed {
      --link-background-color: var(--color-accent-container-active);
    }
  }
}

.link {
  --default-grid-columns: var(--size-24) auto;

  display: grid;
  padding: var(--spacing-6) var(--spacing-8);
  border-radius: var(--border-radius-8);
  background: var(--link-background-color);
  gap: var(--spacing-12);
  grid-template-columns: var(--default-grid-columns);
  white-space: nowrap;

  &:has(:nth-child(3)) {
    grid-template-columns: var(--default-grid-columns) 1fr;
  }
}

.hideForCollapsed {
  opacity: var(--collapsible-content-opacity);
}

.hideForCollapsedWithTransition {
  composes: hideForCollapsed;
  transition: opacity var(--duration-medium-02) ease-in-out;
}
