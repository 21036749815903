@use '~/styles' as *;

.m-overlay {
  position: fixed;
  z-index: var(--z-index-old-menu);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: hidden;
}
