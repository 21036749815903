@use '~/styles' as *;

.root {
  display: grid;
  gap: var(--spacing-2);
}

.horizontal {
  display: inline-flex;
  align-items: center;
}

.segmented {
  composes: horizontal;
  width: fit-content;
  padding: var(--spacing-2);
  border-radius: var(--border-radius-8);
  background-color: var(--color-neutral-container-soft);
  gap: var(--spacing-2);
}

.chip {
  composes: horizontal;
  gap: var(--spacing-6);
}

.radio {
  composes: horizontal;
  gap: var(--spacing-16);
}

.verticalRadio {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-12);
}
