@mixin blueprint-edit-section($color) {
  display: flex;
  min-width: 358px;
  max-width: 60vw;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;
  padding: var(--spacing-4);
  overflow-x: auto;

  legend {
    padding: 0 var(--spacing-8);
    margin: 0 var(--spacing-8);
    color: $color;
  }
}

@mixin node-header-action {
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    fill: var(--color-fg-soft);
  }

  padding: var(--spacing-4);
  margin: 0 var(--spacing-4);

  &:hover {
    background-color: var(--color-success-default);
    opacity: 1;

    svg {
      fill: var(--color-static-white);
    }
  }
}

@mixin edit {
  position: relative;
  min-width: 200px;
  padding: var(--spacing-8) 0;

  &:hover {
    svg {
      fill: var(--color-fg-soft);
    }
  }
}

@mixin edit-toggle {
  position: absolute;
  top: var(--spacing-8);
  right: var(--spacing-8);
  cursor: pointer;

  svg {
    width: var(--spacing-16);
    height: var(--spacing-16);
    fill: var(--color-fg-muted);
  }
}
