@use '~/styles' as *;

.small516 {
  --modal-width: 516px;
}

.medium950 {
  --modal-width: 950px;
}

.large1192 {
  --modal-width: 1192px;
}

.overlay {
  --modal-min-height: 252px;
  --modal-border-radius: var(--border-radius-16);
  --modal-backdrop-color: var(--color-neutral-container-active);
  --modal-background-color: var(--color-bg-surface-1);
  --modal-vertical-spacing: var(--spacing-80);
  --modal-padding: var(--spacing-32);
  --sticky-buttons-padding: var(--spacing-12);
  --content-bottom-padding: calc(
    var(--modal-padding) - var(--sticky-buttons-padding) + var(--border-width-1)
  );

  z-index: calc(var(--z-index-modal) + var(--modal-stack-index));
  display: grid;
  padding: var(--modal-vertical-spacing) 0;
  background: var(--modal-backdrop-color);
  place-items: center;
}

.header {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: var(--modal-padding);
}

.modal {
  display: grid;
  width: var(--modal-width);
  height: max-content;
  min-height: var(--modal-min-height);
  border-radius: var(--modal-border-radius);
  background: var(--modal-background-color);
  grid-template-rows: auto 1fr auto;
  outline: none;
}

.content {
  width: var(--modal-width);
  padding: 0 var(--modal-padding) var(--content-bottom-padding);
}

.buttons {
  position: sticky;
  z-index: calc(var(--z-index-base) + 1);
  bottom: calc(
    -1 * var(--modal-vertical-spacing) - var(--content-bottom-padding)
  );
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: var(--modal-padding);
  padding: calc(var(--sticky-buttons-padding) - var(--border-width-1))
    var(--modal-padding) var(--modal-padding);
  border-radius: 0 0 var(--modal-border-radius) var(--modal-border-radius);
  border-top: var(--border-width-1) solid transparent;
  animation: --keyframes-sticky-buttons linear;
  animation-timeline: scroll();
  background: var(--modal-background-color);
  gap: var(--spacing-16);
}

@keyframes --keyframes-sticky-buttons {
  0%,
  98.999% {
    border-color: var(--color-border-muted);
  }

  99%,
  100% {
    border-color: transparent;
  }
}

.fadeIn {
  animation: --keyframes-fade-in var(--duration-medium-02) ease-out forwards;
}

.shake {
  animation: --keyframes-shake var(--duration-slow-03) linear;
}

@keyframes --keyframes-fade-in {
  from {
    background: rgb(var(--modal-backdrop-color) / 0%);
  }

  to {
    background: var(--modal-backdrop-color);
  }
}

.fadeOut {
  animation: --keyframes-fade-out var(--duration-medium-02) ease-out forwards;
}

@keyframes --keyframes-fade-out {
  from {
    background: var(--modal-backdrop-color);
  }

  to {
    background: rgb(var(--modal-backdrop-color) / 0%);
  }
}

@keyframes --keyframes-shake {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.01);
  }

  100% {
    transform: scale(1);
  }
}
