@mixin field-border {
  --field-border-color: transparent;

  border: 1px solid;
  border-color: var(--field-border-color);
  border-radius: var(--border-radius-8);
  background-color: var(--color-static-white);

  &:focus:not(.disabled),
  &:focus-visible:not(.disabled),
  &:focus-within:not(.disabled) {
    --field-border-color: var(--color-accent-default) !important;

    outline: none;
  }

  &.noBorder,
  &.no-border {
    --field-border-color: transparent !important;

    &:focus:not(.disabled),
    &:focus-visible:not(.disabled),
    &:focus-within:not(.disabled) {
      --field-border-color: transparent !important;
    }
  }

  &.error {
    --field-border-color: var(--color-error-default) !important;

    &:focus:not(.disabled),
    &:focus-visible:not(.disabled),
    &:focus-within:not(.disabled) {
      --field-border-color: var(--color-error-default) !important;
    }
  }
}
