@use '~/styles' as *;

.root {
  --edit-company-table-columns: var(--edit-company-small-input-width)
    repeat(3, var(--edit-company-medium-input-width)) 1fr var(--size-24);

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-12);
}
