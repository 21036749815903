@use '~/styles' as *;

.root {
  --alert-width: 400px;

  position: relative;
  width: var(--alert-width);
  border-radius: var(--border-radius-8);
  box-shadow: var(--shadow-large);
  contain: paint;

  &::before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: var(--border-width-4);
    background-color: var(--notification-default-color);
    content: '';
  }

  .callout {
    --callout-padding-16: calc(var(--spacing-16) - var(--border-width-1));
    --callout-padding-8: calc(var(--spacing-8) - var(--border-width-1));

    padding: var(--callout-padding-16) var(--callout-padding-16)
      var(--callout-padding-16) var(--callout-padding-8);
    border: var(--border-width-1) solid var(--notification-border-color);
  }
}
