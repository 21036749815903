@use '~/styles' as *;

.root {
  --item-height: 64px;

  display: flex;
  height: var(--item-height);
  align-items: center;
  justify-content: space-between;
  padding: var(--spacing-16);
  border-radius: var(--border-radius-8);
  background-color: var(--color-neutral-30);
}
