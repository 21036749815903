@use '~/styles' as *;

.m-menu {
  width: max-content;
  min-width: 150px;
  padding: var(--spacing-8) 0;
  list-style: none;
}

.m-menu-item {
  padding: var(--spacing-8);
  cursor: pointer;

  &:hover {
    background-color: var(--color-accent-opaque-container-default);
  }
}

.m-menu-item-delemiter {
  height: 0;
  border-top: 1px solid var(--color-neutral-opaque-container-default);
  margin: var(--spacing-4) 0;
}
