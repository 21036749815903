@use '../abstracts/' as *;

.sectionHeader {
  margin-bottom: var(--spacing-8);

  &:not(:first-child) {
    margin-top: var(--spacing-24);
  }
}

.singleColumnForm {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-16);
}

.twoColumnForm {
  display: grid;
  align-items: flex-start;
  gap: var(--spacing-16);
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.threeColumnForm {
  display: grid;
  align-items: flex-start;
  gap: var(--spacing-16);
  grid-template-columns: repeat(3, minmax(0, 1fr));
}
