@use '~/styles' as *;

.root {
  display: grid;
  padding: var(--spacing-16);
  border-radius: var(--border-radius-8);
  background-color: var(--color-bg-surface-3);
  justify-items: center;
  text-align: center;
}

.dragActive {
  background-color: var(--color-neutral-75);
}

.disabled {
  cursor: not-allowed;
}
