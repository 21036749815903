@use '~/styles' as *;

.input {
  @include field-border;
  @include body-small;

  padding: var(--spacing-8);

  &::placeholder {
    color: var(--color-fg-muted);
    letter-spacing: 0.0275em;
  }

  &::-webkit-datetime-edit {
    color: var(--color-fg-default);
  }

  &::-webkit-datetime-edit-text {
    color: var(--color-fg-default);
  }

  &.full {
    width: 100%;
  }
}
