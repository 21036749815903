@use '~/styles' as *;

.root {
  --rows-count: 2;

  display: grid;
  align-items: center;
  margin-bottom: var(--spacing-8);
  gap: 0 var(--spacing-8);
  grid-template-columns: 1fr 1fr auto;
  grid-template-rows: repeat(var(--rows-count), auto);

  &:has(.input :nth-child(3)) {
    --rows-count: 3;
  }
}

.input {
  grid-row: 1 / span var(--rows-count);
  grid-template-rows: subgrid;
}

.icon {
  margin-left: calc(-1 * var(--spacing-4));
  grid-row: 2;
}
