@use '~/styles' as *;

.root {
  cursor: pointer;
  user-select: none;

  &.focused .segmentedTabButton {
    padding: var(--spacing-2) var(--spacing-6);
    border: var(--border-width-2) solid
      var(--color-neutral-opaque-container-muted);
    border-radius: var(--border-radius-8);
    outline: var(--border-width-focus) solid var(--color-focus-default);
  }

  &:not(.selected) .segmentedTabButton {
    color: var(--color-fg-soft);
  }
}

.selected {
  .segmentedTabButton {
    border: var(--border-width-1) solid var(--color-border-muted);
    background: var(--color-bg-surface-1);
  }
}

.segmentedTabButton {
  padding: calc(var(--spacing-4) - var(--border-width-1))
    calc(var(--spacing-8) - var(--border-width-1));
  border: var(--border-width-1) solid transparent;
  border-radius: var(--border-radius-6);
  white-space: nowrap;

  &:hover {
    background: var(--color-neutral-on-neutral);
  }

  &:active {
    border: var(--border-width-1) solid var(--color-border-soft);
  }
}
