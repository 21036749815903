@use '~/styles' as *;

.content {
  display: flex;
  flex-direction: row;
  padding: var(--spacing-8);
}

.operator {
  margin-right: var(--spacing-8);
}

.children {
  flex-grow: 1;
}
