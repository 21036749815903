@use '~/styles' as *;

.root {
  --card-border-width: var(--border-width-2);
  --padding-without-border: var(--spacing-24);
  --padding-with-border: calc(
    var(--padding-without-border) - var(--card-border-width)
  );

  --card-background: var(--color-bg-surface-1);

  border: var(--card-border-width) solid transparent;
  border-radius: var(--border-radius-12);
  background: var(--card-background);
  transition: border-color var(--duration-fast-01) ease-in-out;
}

.viewingRoot {
  composes: root;
}

.editingRoot {
  composes: root;
  --card-background: var(--color-accent-opaque-container-soft);

  border-color: var(--color-accent-muted);
}

.header {
  padding: var(--padding-with-border) var(--padding-with-border) 0
    var(--padding-with-border);
}

.children {
  padding: var(--padding-without-border) var(--padding-with-border)
    var(--padding-with-border) var(--padding-with-border);
  border-radius: var(--border-radius-12);
  background: var(--color-bg-surface-1);
}

.accordionChildren {
  position: relative;
  overflow: hidden;

  &:not(:has([data-is-accordion-animating='true'])) {
    transition: max-height var(--duration-fast-01) ease-out;
  }
}
