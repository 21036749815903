@use '~/styles' as *;

.root {
  --border-color: transparent;
}

.inputContainer {
  position: relative;
  display: flex;
  height: var(--control-height);
  align-items: center;
  justify-content: space-between;
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius-8);
  background-color: var(--background-color);
  cursor: pointer;
  user-select: none;
  white-space: nowrap;

  &:focus-within {
    --border-color: var(--color-accent-default);
  }
}

.light {
  --background-color: var(--color-static-white);
}

.dark {
  --background-color: var(--color-neutral-30);
}

.date {
  padding: 0 var(--spacing-8);
  border-radius: var(--border-radius-4);
}

.highlightedDate {
  composes: date;
  background: var(--color-warning-container-hover);
}

.input {
  position: absolute;
  width: 100%;
  inset: 0;
  opacity: 0;
  pointer-events: none;
}
