@use '~/styles' as *;

.root {
  --button-size: 20px;
  --dot-size: 12px;
  --button-color: var(--color-accent-default);

  display: grid;
  align-items: center;
  border-radius: var(--border-radius-8);
  cursor: pointer;
  gap: var(--spacing-4);
  grid-template-columns: auto auto;
  user-select: none;

  &.focused {
    outline: var(--border-width-focus) solid var(--color-focus-default);
    outline-offset: var(--spacing-2);
  }
}

.selected {
  .dot {
    scale: 1;
  }
}

.radioButton {
  display: flex;
  width: var(--button-size);
  height: var(--button-size);
  align-items: center;
  justify-content: center;
  border: 2px solid var(--button-color);
  border-radius: 50%;
}

.dot {
  width: var(--dot-size);
  height: var(--dot-size);
  border-radius: 50%;
  background-color: var(--button-color);
  scale: 0;
  transition: scale var(--duration-fast-01) ease-out;
}
