@font-face {
  font-display: swap;
  font-family: Graphik;
  font-style: normal;
  font-weight: 400;
  src: url('../../public/fonts/Graphik-Regular-Web.eot');
  src:
    url('../../public/fonts/Graphik-Regular-Web.eot#iefix')
      format('embedded-opentype'),
    url('../../public/fonts/Graphik-Regular-Web.woff2') format('woff2'),
    url('../../public/fonts/Graphik-Regular-Web.woff') format('woff'),
    url('../../public/fonts/Graphik-Regular-Web.ttf') format('truetype');
}

@font-face {
  font-display: swap;
  font-family: Graphik;
  font-style: normal;
  font-weight: 500;
  src: url('../../public/fonts/Graphik-Medium-Web.eot');
  src:
    url('../../public/fonts/Graphik-Medium-Web.eot#iefix')
      format('embedded-opentype'),
    url('../../public/fonts/Graphik-Medium-Web.woff2') format('woff2'),
    url('../../public/fonts/Graphik-Medium-Web.woff') format('woff'),
    url('../../public/fonts/Graphik-Medium-Web.ttf') format('truetype');
}

@font-face {
  font-display: swap;
  font-family: Graphik;
  font-style: normal;
  font-weight: 700;
  src: url('../../public/fonts/Graphik-Bold-Web.eot');
  src:
    url('../../public/fonts/Graphik-Bold-Web.eot#iefix')
      format('embedded-opentype'),
    url('../../public/fonts/Graphik-Bold-Web.woff2') format('woff2'),
    url('../../public/fonts/Graphik-Bold-Web.woff') format('woff'),
    url('../../public/fonts/Graphik-Bold-Web.ttf') format('truetype');
}
