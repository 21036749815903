@use '~/styles' as *;

.root {
  --icon-default-color: var(--color-neutral-default);
  --icon-hover-color: var(--color-accent-hover);
  --icon-pressed-color: var(--color-accent-active);
  --icon-selected-color: var(--color-accent-default);

  --star-icon-stroke-color: var(--icon-default-color);
  --star-icon-fill-color: transparent;

  // Needed for VisuallyHidden to work properly
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;

  &.selected {
    --star-icon-fill-color: var(--icon-selected-color);
    --star-icon-stroke-color: transparent;
    --star-icon-outline-color: transparent;
  }

  &:hover {
    --star-icon-fill-color: var(--icon-default-color);
    --star-icon-stroke-color: transparent;
    --star-icon-outline-color: transparent;

    &.selected {
      --star-icon-fill-color: var(--icon-hover-color);
    }
  }

  &.focused {
    --star-icon-fill-color: var(--icon-default-color);
    --star-icon-stroke-color: transparent;
    --star-icon-outline-color: var(--color-focus-default);

    &.selected {
      --star-icon-fill-color: var(--icon-hover-color);
    }
  }

  &.pressed {
    --star-icon-fill-color: var(--icon-pressed-color);
    --star-icon-stroke-color: transparent;
    --star-icon-outline-color: transparent;
  }
}
