@use '~/styles' as *;

.medium {
  --tile-height: 196px;
  --max-title-lines: 2;
  --max-description-lines: 2;
}

.large {
  --tile-height: 260px;
  --max-title-lines: 3;
  --max-description-lines: 4;
}

.root {
  --color-bg-default: var(--color-bg-surface-1);
  --color-bg-active: var(--color-bg-surface-2);

  position: relative;
  display: flex;
  height: var(--tile-height);
  flex-direction: column;
  padding: var(--spacing-16);
  border-radius: var(--border-radius-8);
  background: var(--color-bg-default);
  outline: none;

  &:hover,
  &:active,
  &:focus-visible,
  &:has([data-is-context-menu-open='true']) {
    background: var(--color-bg-active);
    outline: var(--border-width-focus) solid var(--color-border-muted);
  }

  // Don't show pressed state, when we're clicking on inner interactive elements
  &:active:not(:has(:focus)) {
    outline-color: var(--color-border-soft);
  }

  &.loading {
    background: var(--color-bg-default);
    outline: none;
  }

  &.disabled {
    background: var(--color-neutral-container-muted);
    cursor: not-allowed;
    outline: none;

    .title,
    .description {
      color: var(--color-fg-disabled);
    }
  }

  &:focus-visible {
    &::before {
      position: absolute;
      z-index: var(--z-index-base);
      border-radius: var(--border-radius-10);
      content: '';
      inset: calc(var(--border-width-focus) * -1);
      outline: var(--border-width-focus) solid var(--color-focus-default);
      outline-offset: 1px;
      pointer-events: none;
    }
  }
}

.clampLines {
  display: -webkit-box;
  overflow: hidden;
  max-height: calc(var(--max-lines) * 1lh);
  -webkit-box-orient: vertical;
  hyphens: auto;
  -webkit-line-clamp: var(--max-lines);
  text-overflow: ellipsis;
}

.title {
  composes: clampLines;

  --max-lines: var(--max-title-lines);
}

.description {
  composes: clampLines;

  --max-lines: var(--max-description-lines);

  color: var(--color-fg-soft);
}
