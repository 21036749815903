@use '~/styles' as *;

.root {
  --empty-state-width: 500px;

  display: flex;
  width: var(--empty-state-width);
  max-width: var(--empty-state-width);
  flex-direction: column;
  align-items: center;
  justify-content: center;

  // TODO rework app layout to make possible vertical center alignement and remove margin
  margin: 300px auto 0;
  text-align: center;
}
