@use '~/styles' as *;

.root {
  display: flex;
  min-height: var(--spacing-24);
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: var(--spacing-8) 0;

  svg {
    height: var(--size-16);
    fill: var(--color-static-white);
  }
}

.link {
  flex: 1;
  margin-left: var(--spacing-24);

  a,
  p {
    color: var(--color-fg-default);
    text-decoration: none;
  }
}
