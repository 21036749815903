@use '~/styles' as *;

.chartWithCards {
  --card-width: 248px;

  display: grid;
  gap: var(--spacing-32);

  @media (--breakpoint-large-1280-up) {
    grid-template-columns: 1fr var(--card-width);

    & > :first-child {
      order: 2;
    }

    & > :last-child {
      order: 1;
    }
  }
}

.cards {
  display: grid;
  gap: var(--spacing-16);

  @media (--breakpoint-medium-768) {
    grid-template-columns: repeat(2, minmax(0, 1fr));

    & > :last-child {
      grid-column: span 2;
    }
  }
}
