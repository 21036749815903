@use '~/styles' as *;

.m-link-button {
  position: relative;
  padding: 0;
  border: none;
  border: 1px dotted transparent;
  background: none;
  color: var(--color-fg-default);
  cursor: pointer;
  outline: none;

  @include body-small;

  &.muted {
    color: var(--color-fg-soft);
  }

  & svg {
    fill: var(--color-fg-default);
  }

  &:active,
  &:focus {
    border: 1px dotted var(--color-fg-default);
  }
}
