@use './abstracts/' as *;

html,
body,
#root {
  min-width: 100%;
  height: 100%;
  min-height: 100dvh;
  box-sizing: border-box;
  background: var(--color-bg-page);
  color: var(--color-fg-default);
  font-family: Graphik, sans-serif;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeSpeed;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*,
*::before,
*::after {
  -webkit-tap-highlight-color: var(--color-focus-default);
  transition:
    color var(--duration-fast-01) ease-out,
    background var(--duration-fast-01) ease-out;
}

svg {
  fill: currentcolor;
  transition: fill var(--duration-fast-01) ease-out;
}

a {
  color: var(--color-fg-default);
  text-decoration: none;
}

hr {
  border: none;
  border-bottom: 1px var(--color-fg-muted) solid;
  opacity: 0.25;
}

// Workaround for m-control styling, should be replaced with themes in new components
.light-panel .m-control {
  background-color: var(--color-neutral-30) !important;
}

// TODO move these closer to the components and remove global classes

.m-editor {
  @include edit;
}
