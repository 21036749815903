@use './__generated__/tokens.css';
@use './abstracts' as *;

:root {
  // TODO make design tokens for layouts
  --layout-width: 1128px;

  // TODO make design tokens for controls
  --control-height: var(--size-36);

  @each $color, $shades in $colors {
    @each $shade, $value in $shades {
      --color-#{$color}-#{'' + $shade}: #{$value};
    }
  }
}
