@use '~/styles' as *;

.event {
  display: grid;
  align-items: center;
  gap: 0 var(--spacing-4);
  grid-template-columns: auto 1fr;

  & :last-child {
    grid-column: 2;
  }
}

.circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.deleteButton {
  position: absolute;
  top: 0;
  right: var(--spacing-8);
  bottom: 0;
  display: flex;
  align-items: center;
  opacity: var(--table-row-content-hovered-opacity);

  &:focus-within {
    opacity: 1;
  }
}
